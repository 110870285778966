import React from "react";
import { Col, Container, Form, FormGroup, Row, Input } from "reactstrap";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { AiOutlineCreditCard } from "react-icons/ai";
import stripeimg from '../../assets/images/img/stripe.png'
import chashon from '../../assets/images/img/paypal.png'
import bank from '../../assets/images/img/bank-transfer.png'
import { useState } from "react";
import CashOnDelivery from "../paymentMethod.jsx/CashOnDelivery";
import BankTransfer from "../paymentMethod.jsx/BankTransfer";
import InternationalCard from "../paymentMethod.jsx/InternationalCard";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";


const OrderShipping = (props) => {
    const [payment, setPayment] = useState(0);
    const [shippingInfo, setShippingInfo] = useState([]);
    const { t } = useTranslation()


    useEffect(() => {
        setShippingInfo(props.shippingInfo);

    }, [])
    const paymentMethod = (method) => {
        setPayment(method)
    }

    const cashonDelivery = () => {
        alert('cashondelivery')
    }

    const bankTransfer = () => {
        alert('bankTransfer')
    }

    const internationlPayment = () => {
        alert('internationlPayment')
    }



    const confirmPayment = (data) => {
        data.type === 'cashonDelivery' && cashonDelivery()
        data.type === 'stripe' && internationlPayment()
        data.type === 'bankTransfer' && bankTransfer()

    }
    return <div>
            <div>
                <div>
                    <h6 className="mb-1 pb-3 checkout__txt ">{t("payment_method")}</h6>
                    <div className="paycard_div">
                        <div className="paymentIcon payicontop" onClick={() => paymentMethod(1)}>
                            <span htmlFor="cash">{t("paypal")}</span>
                            <div className="imgBackground">
                                <img src={chashon} style={{ height: "40px", marginLeft: "2px", width: "80px" }} />
                            </div>
                        </div>
                        <div className="paymentIcon payicontop" onClick={() => paymentMethod(3)}>
                            <span htmlFor="cash">{t("bank_transfer")}</span>
                            <div className="imgBackground">
                                <img src={bank} style={{ height: "40px", marginLeft: "2px", width: "fit-content"}} />
                            </div>
                        </div>
                        <div className="paymentIcon" onClick={() => paymentMethod(2)}>
                            <span htmlFor="payment">{t("international_payment")}</span>
                            <div className="imgBackground">
                                <img src={stripeimg} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="payment__div">
                {
                    payment>0 && <div className="finalPayment">
                        {
                            payment == 1 && <CashOnDelivery payment={confirmPayment} shippingInfo={props.shippingInfo} /> || payment == 3 && <BankTransfer payment={confirmPayment} shippingInfo={props.shippingInfo} /> || payment == 2 && <InternationalCard payment={confirmPayment} shippingInfo={props.shippingInfo} />
                        }
                    </div>
                }
                </div>
            </div>
    </div>
}
export default OrderShipping;