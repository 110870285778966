import React, { useRef, useEffect, useState } from "react";
import "./header.css";
import { LANGUAGES } from "../../constants";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { userAction } from "../../redux/slices/userSlice";
import { cartActions } from "../../redux/slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { useStateContext } from "../../contexts/ContextProviders";
import logo from "../../../src/assets/images/img/BIOM_logo_white.png";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import { getWishcount } from "../../redux/slices/settingSlice";
import { useTranslation } from "react-i18next";

// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

import {
  Button,
  Menu,
  MenuItem,
  Fade,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { AiFillCaretDown, AiOutlineUser } from "react-icons/ai";
import manueing from "../../assets/menu.svg";
import cart from "../../assets/cart.svg";
import wishlist from "../../assets/wishlist.svg";

// import { AiOutlineHeart,AiOutlineShoppingCart } from "react-icons/ai";
// import { BottomNavigationAction,BottomNavigation } from '@mui/material';
// import { AiOutlineCustomerService } from "react-icons/ai";

import MobileNavbar from "./MobileNavbar";
import { useQuery } from "react-query";

const nav__links = [
  {
    path: "home",
    display: "home",
  },
  {
    path: "all/product",
    display: "Products",
  },

  {
    path: "all/brands",
    display: "offers",
  },
  {
    path: "contact/us",
    display: "contact",
  },
];

const Header = () => {
  const { lang } = useStateContext();
  const { setLocale } = useStateContext();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const headerRef = useRef(null);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const totalPrice = useSelector((state) => state.cart.totalAmount);
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const wishcount = useSelector((state) => state.setting.wishcount);
  // const username = islogin && JSON.parse(userinfo).name;
  const basepath = useSelector((state) => state.setting.basepath);

  const menuRef = useRef(null);
  const navigate = useNavigate();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    dispatch(getWishcount());
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl22, setAnchorEl22] = useState(null);
  const open22 = Boolean(anchorEl22);
  const handleClick22 = (event) => {
    setAnchorEl22(event.currentTarget);
  };
  const handleClose22 = () => {
    setAnchorEl22(null);
  };

  const userLogout = () => {
    setDropdownOpen(false);
    setAnchorEl22(false);
    axiosClient.post("logout").then(({ data }) => {
      dispatch(userAction.logout());
      dispatch(cartActions.removeCartAllItem());
      localStorage.clear();
      toast.success(`${t("successfully_logout")}`);
      navigate("/home");
    })
      .catch(e => console.error(e));
  };

  const [showSubcategory, setSubcategory] = useState(false);
  const [subcategory, setSubcatListegory] = useState([]);
  const categoryList = useQuery("categorylist", async () => {
    try {
      const response = await axiosClient.get(`product/category?lang=${lang}`);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  });

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");
  const navigateToCart = () => {
    navigate("/cart");
  };
  const [srcname, setsrcname] = useState();

  const [srcProductList, setSrcProductList] = useState([]);

  const searchProduct = (name) => {
    setsrcname(name);
    try {
      axiosClient.get(`search/product?name=${name}`).then(({ data }) => {
        if (name) {
          setSrcProductList(data);
        } else {
          setSrcProductList([]);
        }
      })
        .catch(e => console.error(e));
    } catch (error) {
      console.error(error);
    }
  };

  const subcategoryset = (categoryId) => {
    try {
      axiosClient
        .get(`category/wise/subcategory?category_id=${categoryId}`)
        .then(({ data }) => {
          setSubcatListegory(data);
          if (data.length > 0) {
            setSubcategory(true);
          }
        })
        .catch(e => console.error(e));
    } catch (error) {
      console.error(error);
    }
  };

  const srcInputname = (name) => {
    setsrcname(name);
    setSrcProductList([]);
  };

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    setLocale(lang_code);
  };

  return (
    <>
      {/* Header Top Strip  */}

      <header className="header-top-strip py-2">
        <div className="container">
          <div className="row justify-content-center align-items-center p-0">
            <div className="col-lg-12 col-md-12 d-flex justify-content-end align-items-center header-navbar p-0">
              <div className="text-center text-md-end">
                <div className="my__account userst">
                  {islogin ? (
                    <div
                      className="d-flex align-items-center header-text"
                      style={{ color: "#18967b" }}
                      to={"/login"}
                    >
                      <div className="userimgdiv">
                        <span className="userimg">
                          <AiOutlineUser />
                        </span>
                      </div>

                      <div className="dropdown">
                        <div>
                          <Button
                            id="fade-button2"
                            aria-controls={open22 ? "fade-menu3" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open22 ? "true" : undefined}
                            className="username"
                            onClick={handleClick22}
                          >
                            {islogin && JSON.parse(userinfo).name}
                            <span className="userdropicon">
                              <AiFillCaretDown />
                            </span>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center gap-10 header-text"
                      style={{ color: "#18967b" }}
                    >
                      <Link to={"/login"}>
                        <p
                          className="mb-0 header-text"
                          style={{
                            color: "#18967b",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {t("login")}{" "}
                        </p>
                      </Link>{" "}
                      |
                      <Link to="/Signup">
                        <p
                          className="mb-0 header-text"
                          style={{
                            color: "#18967b",
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {t("signup")}{" "}
                        </p>
                      </Link>
                    </div>
                  )}
                  <Menu
                    id="fade-menu3"
                    // MenuListProps={{
                    //   'aria-labelledby': 'fade-button2',
                    // }}
                    anchorEl={anchorEl22}
                    open={open22}
                    onClose={handleClose22}
                    TransitionComponent={Fade}
                  >
                    <Link to={"/user/deshboard/1"}>
                      <MenuItem
                        className="header-text"
                        onClick={handleClose22}
                        style={{
                          color: "#18967b",
                          textTransform: "capitalize",
                        }}
                      >
                        {t("profile")}
                      </MenuItem>
                    </Link>

                    {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                    <MenuItem
                      className="header-text"
                      onClick={userLogout}
                      style={{ color: "#18967b", textTransform: "capitalize" }}
                    >
                      {t("logout")}
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div className="mx-3">
                <select
                  defaultValue={i18n.language}
                  onChange={onChangeLang}
                  className="header-text"
                  style={{ textTransform: "capitalize" }}
                >
                  {LANGUAGES.map(({ code, label }) => (
                    <option
                      key={code}
                      value={code}
                      className="header-text"
                      style={{ color: "#18967b", textTransform: "capitalize" }}
                    >
                      {label[i18n.language]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Header Middle Start  */}
      <header className="header-middle py-2">
        <div className="container">
          <div className="row align-items-center gx-3">
            <div className="col-lg-4 col-md-3 col-sm-6">
              <div className="logo">
                <Link to={"/"}>
                  <img src={logo} alt="logo" />
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-md-block d-sm-none">
              <div className="search-group position-relative">
                <input
                  onChange={(e) => {
                    searchProduct(e.target.value);
                  }}
                  defaultValue={srcname}
                  type="text"
                  className=""
                  placeholder={t("search_product_here")}
                  aria-label={t("search_product_here")}
                  aria-describedby="basic-addon2"
                />
                <span className="search-icon" id="basic-addon2">
                  <i className="ri-search-line fs-6"></i>
                </span>

                {/* {srcProductList && (
                  <ul className="srcitem">
                    {srcProductList.map((data, index) => (
                      <Link
                        to={`/shop/${data.id}`}
                        className="linkst"
                        onClick={() => srcInputname(data.name)}
                      >
                        <li className="srcItem">{data.name}</li>
                      </Link>
                    ))}
                  </ul>
                )} */}
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-6 wish_cart">
              <div className="header-middle-links">
                <div className="wishlist">
                  <Link
                    className="d-flex align-items-center gap-10 text-white"
                    to={"/product/wishlist"}
                  >
                    {/* <AiOutlineHeart className="headerIcondesign"/> */}

                    <img src={wishlist} alt="wishlist" />
                    <div>
                      <span
                        className="badge bg-white text-dark cardamount"
                        onClick={navigateToCart}
                      >
                        {wishcount}
                      </span>
                      <p
                        className="mb-0 header-text"
                        style={{
                          color: "#18967b",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {t("wishlist")}{" "}
                      </p>
                    </div>
                  </Link>

                  {/* <SimpleListMenu/> */}
                </div>
                <SimpleListMenu total={totalQuantity} />
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileNavbar />

      {/* Header Bottom Start  */}
      <header className="header-bottom py-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                className="menu-links m-0"
                ref={menuRef}
                onClick={menuToggle}
              >
                <ul className="justify-content-center py-3">
                  {nav__links.map((item, index) => (
                    <li className="nav__item" key={index}>
                      <NavLink
                        to={item.path}
                        className={(navClass) =>
                          navClass.isActive ? "nav__active" : "header-text"
                        }
                        style={{ color: "#18967b" }}
                      >
                        {t(`${item.display}`)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

    </>
  );
};

const SimpleListMenu = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const basepath = useSelector((state) => state.setting.basepath);
  const open = Boolean(anchorEl);

  const cartItems = useSelector((state) => state.cart.cartItems);

  const options = [
    "Show some love to MUI",
    "Show all notification content",
    "Hide sensitive notification content",
    "Hide all notification content",
  ];
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List
        component="nav"
        aria-label="Device settings"
      // sx={{ bgcolor: 'background.paper' }}
      >
        <span
          // id="lock-button"
          // aria-haspopup="listbox"
          // aria-controls="lock-menu"
          // aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
        >
          <div className="cart__list" style={{ cursor: "pointer" }}>
            <div className="d-flex align-items-center gap-10 text-white">
              <img src={cart} alt="Cart" />
              {/* <AiOutlineShoppingCart className="headerIcondesign"/> */}
              <div className="d-flex flex-column">
                <span
                  className="badge bg-white text-dark cardamount header-text"
                  style={{ color: "#18967b" }}
                // onClick={navigateToCart}
                >
                  {props.total}
                </span>
                <b />
                <p
                  className="mb-0 header-text"
                  style={{ color: "#18967b", textTransform: "capitalize" }}
                >
                  {t("cart")}
                  {/* ${totalPrice} */}
                </p>
              </div>
            </div>
          </div>
        </span>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <div className="cartdropwidth">
          {cartItems &&
            cartItems.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  className="cartdwnsst"
                  style={{
                    width: "300px",
                    dispatch: "flex",
                    flexWrap: "wrap",
                    gap: "2px",
                    border: "1px solid rgb(243 236 236 / 45%)",
                  }}
                >
                  <div style={{ flexBasis: "30%", position: "relative" }}>
                    <span
                      className="itmqty_sp header-text"
                      style={{ color: "#18967b" }}
                    >
                      {item.quantity}
                    </span>
                    <img
                      className="item__img"
                      src={`${basepath}/${item.imgUrl}`}
                      alt=""
                    />
                  </div>
                  <div style={{ flexBasis: "65%" }}>
                    {item.productName && item.productName.length > 15
                      ? item.productName.slice(0, 15) + ".."
                      : item.productName}
                    <br />
                    <p className="header-text" style={{ color: "#18967b" }}>
                      {item.price * item.quantity}
                    </p>
                  </div>
                  {/* <div style={{ flexBasis: "10%" }}>
                    {item.price * item.quantity}
                  </div> */}
                </MenuItem>
              );
            })}
          <Link to={"/checkout"} className="w-100">
            <MenuItem
              className="bottomdivst d-flex justify-content-center header-text"
              style={{
                background: "red",
                background: "#c22026",
                marginBottom: "-8px",
                color: "#18967b",
                textTransform: "capitalize",
              }}
            >
              {t("checkout")}
            </MenuItem>
          </Link>
        </div>
      </Menu>
    </div>
  );
};

export default Header;
