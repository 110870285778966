import React, { useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import {
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
    FormText,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';


import {
    AiOutlineHeart,
    AiOutlineShopping,
    AiOutlineCheckCircle,
} from "react-icons/ai";
import axiosClient from "../../axios-client";

const ChangePassword = () => {
    const [currentPass,setCurrentPass]=useState('');
    const [newPass,setNewPass]=useState('');
    const [confirmPass,setConfirmPass]=useState('');
    const [confirmError,setConfirmError]=useState(false);
    const { t } = useTranslation();

    const changePassword=()=>{
        let data={
            currentPass:currentPass,
            newPass:newPass,
            confirmPass:confirmPass
        }
        if(newPass!==confirmPass){
            setConfirmError(true);
        }else{
            axiosClient
            .post('user/changePassword', data)
            .then(({ data }) => {
                if(data.status==400){
                    toast.error(data.msg)
                }
                if(data.status==200){
                    toast.success(data.msg)
                }
               
            }).catch((err)=>{ console.error(err) });
        }
      

    }
    return (
        <Helmet title="Login">
            <section>
                <Container className={'main-content'}>
                    <Form>
                    <Row className="dashboardCard">
                       
                        <Col lg="12" className="userProfile__info">
                            <FormGroup className="frmwidth-st">
                            <h5 className="txcenterst">{t("change_password")}</h5>
                    
                            </FormGroup>
                        </Col>
            
                        <Col lg="12" className="userProfile__info">
                            <FormGroup className="frmwidth-st">
                                <Label htmlFor="exampleEmail">{t("current_password")}</Label>
                                <Input type="password" name="current_password" onChange={(e)=>setCurrentPass(e.target.value)} id="exampleEmail" placeholder={t("current_password")} autoComplete='false' />
                            </FormGroup>
                        </Col>
                        <Col lg="12" className="userProfile__info">
                        <FormGroup className="frmwidth-st">
                                <Label htmlFor="exampleEmail">{t("new_password")}</Label>
                                <Input type="password" name="new_password" onChange={(e)=>setNewPass(e.target.value)} id="exampleEmail" placeholder={t("new_password")} autoComplete='false' />
                            </FormGroup>
                        </Col>
                        <Col lg="12" className="userProfile__info">
                            <FormGroup className="frmwidth-st">
                                <Label htmlFor="exampleEmail">{t("confirm_password")}</Label>
                                <Input type="password" name="confirm_password" id="exampleEmail" onChange={(e)=>setConfirmPass(e.target.value)} placeholder={t("confirm_password")} autoComplete='false' />
                              
                              
                              {confirmError&&<span className="errortx">{t("password_not_match")}</span>}
                                
                            </FormGroup>
    
                        </Col>
                        <Col lg="12" className="userProfile__info">
                        <Button color="info" className="frmwidth-st passbtn-st" onClick={changePassword}>{t("save_change")}</Button>
                        </Col>
                    </Row>
                    </Form>
                </Container>
            </section>
        </Helmet>
    );
};

export default ChangePassword;