import React from 'react'
import ProductCard from './ProductCard';
import '../../styles/productSection.css';


const ProductSection = ({ data }) => {

  return (
    <>
      {
        data && typeof(data) == 'object'? (
          <div className='general__card'>
            {data?.map((item, index) => (
              <div key={index} className="general__box"><ProductCard item={item} key={index} /></div>
            ))
            }
          </div>
        ) : (
          <div>There are no any product.</div>
        )
      }
    </>
  )

}

export default ProductSection