import React from 'react'
import './footer.css';
import logo from '../../../src/assets/images/eco-logo.png';
import { Link } from 'react-router-dom';
import { BsLinkedin,BsYoutube,BsFacebook,BsInstagram,BsGithub } from "react-icons/bs";
import { useEffect } from 'react';
import axiosClient from '../../axios-client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import newsletter from "../../assets/images/newsletter.png"
import app1 from "../../assets/images/e-cab-logo-blue.png"
import app2 from "../../assets/images/basis-logo.jpg"
import app3 from "../../assets/images/BKash-Logo.wine.png"
import app4 from "../../assets/images/dutch-bangla-rocket-logo.png"



const Footer = () => {
    const [address,setAddress]=useState('')
    const [phone,setPhone]=useState('')
    const [email,setEmail]=useState('')
    const [facegook,setFacebook]=useState('')
    const [aboutus,setaboutus]=useState('')
    const [privacy,setPrivacy]=useState('')
    const [refund,setRefund]=useState('')
    const [termsCondition,setTermsConditions]=useState('')
    const [shipping,setShipping]=useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const { t } = useTranslation();

    const handleSubscribe = () => {
        if(emailAddress) {
            axiosClient
                .post("user/subscribe", emailAddress)
                .then(({data}) => {
                    if(data.status == 200) {
                        toast.success(`${t("subscribe_successfully")}`);
                    } else {
                        toast.error(`${t("subscribe_failure")}`);
                    }
                    setEmailAddress('');
                })
                .catch((err) => {
                    console.error(err);
                })
        } else {
            toast.error(`${t("enter_email_address")}`);
        }
    }

    useEffect(()=>{
        try {
            axiosClient
            .get("product/company/info")
            .then(({ data }) => {
                setAddress(data.company_address);
                setPhone(data.phone);
                setEmail(data.email);
                setFacebook(data.facebook_link);
                setaboutus(data.about_us);
                setPrivacy(data.privacy_policy);
                setRefund(data.refund_policy);
                setTermsConditions(data.terms_condition);
                setShipping(data.shipping_policy);
                const companyInfo = {
                    'address': data.company_address,
                    'phoneNumber': data.phone,
                    'emailAddress': data.email,
                    'facebook': data.facebook_link,
                    'aboutUs': data.about_us,
                    'privacy': data.privacy_policy,
                    'refund': data.refund_policy,
                    'termsConditions': data.terms_condition,
                    'shipping': data.shipping_policy
                };
                localStorage.setItem('COMPANY_INFO', JSON.stringify(companyInfo))
                // console.log(data)
                // setProductList(data);
            })
            .catch(e => console.error(e.target.value));
        } catch (error) {
            console.error(error)
        }
    },[])

//   const year = new Date().getFullYear()

  return (
    <>
    <div style={{width:"100%",overflow:"hidden"}}>
    {/* <footer className='py-5'>
            <div className="container">
                <div className="row align-items-center gx-5">
                    <div className="col-md-5">
                        <div className="footer-top-data">
                            <img src={newsletter} alt="" />
                            <h2> {t("sign_up_for_news_letter")} </h2>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="subscribe-group">
                            <input 
                                type="email" 
                                className="" 
                                placeholder={t("your_email_address")}
                                aria-label="Your Email Address." 
                                aria-describedby="basic-addon2"
                                value={emailAddress}
                                onChange={(e) => setEmailAddress(e.target.value)}
                            />
                            <span className="subscribe-text p-2" id="basic-addon2" onClick={() => handleSubscribe()}> 
                                {t("subscribe")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}

    </div>
       
        <footer className='py-4'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <h4 className='text-white mb-4'>{t("contact_us")} </h4>
                        <div className='office__info pe-5'>
                            <address className='mt-2 mb-0'>
                                <span className='info_label'>{t("address")}</span>: {address}
                            </address>
                            <a className=' text-white mt-2 mb-0' href='tel:+880 1313 450785'> <span className='info_label'>{t("contact")}</span>: {phone} </a>
                            <br/>
                            <a className=' text-white mt-2 mb-0' href={`mailto:${email}`}> <span className='info_label'>{t("email")}</span>: <span className="email_link">{email}</span> </a>
                            <div className='social_icons d-flex align-items-center gap-15 mt-4'>
                                <Link to=""><BsLinkedin className='fs-5'/></Link>
                                <a href={facegook} target="_blank" ><BsYoutube className='fs-5'/></a>
                                <a href={facegook} target="_blank" ><BsFacebook className='fs-5'/></a>
                                
                                <Link to=""><BsInstagram className='fs-5'/></Link>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h4 className='text-white mb-4'> {t("information")} </h4>
                        <div className='footer-links d-flex flex-column'>

                            {/* <Link className=' text-white py-2 mb-1' to=""> Blogs </Link> */}
                            <Link className=' text-white py-2 mb-1' to={`about/privacy`}> {t("privacy_policy")} </Link>
                            <Link className=' text-white py-2 mb-1'  to={`about/shipping`}>  {t("shipping_policy")}  </Link>
                            <Link className=' text-white py-2 mb-1'  to={`about/refund`}>{t("refund_policy")} </Link>
                            <Link className=' text-white py-2 mb-1'  to={`about/terms/condition`}> {t("terms_conditions")} </Link>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6">
                        <h4 className='text-white mb-4'> {t("company")} </h4>
                        <div className='footer-links d-flex flex-column'>
                            <Link className=' text-white py-2 mb-1' to='aboutus'> {t("about_us")}  </Link>
                            <Link className=' text-white py-2 mb-1' to="faq"> {t("faq")} </Link>
                            <Link className=' text-white py-2 mb-1' to="contact/us">  {t("contact")}  </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <h4 className='text-white mb-4'> {t("our_partners")} </h4>
                        <p className='text-white mb-3'> {t("download_out_app")} </p>
                        <div className='row flex-wrap gy-3'>
                           {/* <div className='col-lg-4 col-md-4'>
                                <Link className='footer-app-links' to=""> <img src={app1} alt="" />  </Link>
                           </div>
                           <div className='col-lg-8 col-md-8'>
                                <Link className='footer-app-links' to=""> <img src={app2} alt="" />  </Link>
                           </div>
                       
                            <div className='col-lg-4 col-md-4'>
                                <Link className='footer-app-links' to=""> <img src={app3} alt="" />  </Link>
                            </div>
                            <div className='col-lg-8 col-md-8'>
                                <Link className='footer-app-links' to=""> <img src={app4} alt="" /> </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <footer className='py-3'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                            <p className='footer__copyright mb-0 text-white align-items-center'>
                                &copy; {new Date().getFullYear()} {t("powered_by")} <Link to="https://biomaschine.de/"> Biaomaschine.</Link> {t("all_rights_reserved")} </p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                    <div className='footer-payment'>
                            <Link to=""> <img src="header-img/cc-badges-ppmcvdam.png" alt="" />  </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>

  )
}



export default Footer;