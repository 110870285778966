import React, { useState } from "react";
import "../../styles/hamburgermenu.css";
import { Link, useNavigate } from "react-router-dom";
import c from "classname";
import { AiOutlineHome, AiOutlineShoppingCart, AiFillHome } from "react-icons/ai";
import { BsSuitHeart, BsPersonCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { userAction } from "../../redux/slices/userSlice";
import axiosClient from "../../axios-client";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import logo from "../../../src/assets/images/img/BIOM_logo_white.png";

const MobileNavbar = () => {
  const [value, setValue] = useState('recents');
  const [isDropmenu, setIsDropmenu] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);

  const navigate = useNavigate();

  const navigateToCart = () => {

    navigate("/checkout");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDropdownMenu = () => {
    setIsDropmenu(!isDropmenu);
  }

  const userLogout = () => {
    axiosClient.post("logout").then(({ data }) => {
      dispatch(userAction.logout());
      toast.success(`${t("successfully_logout")}`);
      navigate("/home");
    })
      .catch(e => console.error(e));
  };

  return (
    <header className="navbar">
      <div className="container">
        <div className="col-9">
          <div className="logo">
            <Link to={"/"}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </div>
        <button className="hamburger-btn" onClick={toggleDropdownMenu}>
          <i className="ri-menu-fill"></i>
        </button>
      </div>
      <nav className={c("nav-menu", {
        'd-block' : isDropmenu,
        'd-none' : !isDropmenu
      })}>
        <ul>
          <li><Link to={"/product/wishlist"}><BsSuitHeart /> {t("wishlist")} </Link></li>
          <li><a href="" onClick={navigateToCart}><AiOutlineShoppingCart /> {t("cart")}</a></li>
          {
            islogin ? (
              <>
                <li><Link to={"/user/deshboard/1"}><BsPersonCircle /> {t("account")}</Link></li>
                <li><a onClick={userLogout}> {t("logout")}</a></li>
              </>
            ) : (
              <>
                <li><Link to={"/login"}> {t("login")}</Link></li>
                <li><Link to="/Signup"> {t("signup")}</Link></li>
              </>
            )
          }
        </ul>
      </nav>
    </header>
  );

}

export default MobileNavbar;