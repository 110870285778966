import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import Login from '../pages/Login';
import Signup from '../pages/Signup';
import Home from '../pages/Home';
import Shop from '../pages/Shop';
import ProductDetails from '../pages/ProductDetails';
import Cart from '../pages/Cart';
import Checkout from '../pages/Checkout';
// import { useParams } from 'react-router-dom';
import UserDashboard from '../pages/userDashboard/UserDashboard';
import WishList from '../pages/Wishlist';
import AllProductList from '../pages/AllproductList';
import AboutUs from '../pages/AboutUs';
import TermsCondition from '../pages/policy/TermsCondition';
import Refund from '../pages/policy/Refund';
import Shipping from '../pages/policy/Shipping';
import Privacy from '../pages/policy/Privacy';
import ContactUs from '../pages/ContactUs';
import Brands from '../components/Brands/Brands';
import AllCategoris from '../components/Categories/AllCategoris';
import Faq from '../pages/Faq';
import NotFound from '../components/UI/NotFound';
import PolicyCookie from '../components/UI/PolicyCookie';


const Routers = () => {
    return (
        <>
            <Routes>
                <Route exact path='/' element={<Navigate to="home" />} />
                <Route exact path='home' element={<Home />} />
                <Route exact path='product/list/:category_id/:subcategory_id/:type/:offer_id' element={<Shop />} />
                <Route exact path='products/list/:category_id/:subcategory_id/:type/:offer/:brand_id' element={<AllProductList />} />
                <Route exact path='product/wishlist' element={<WishList />} />
                <Route exact path='shop/:id' element={<ProductDetails />} />
                <Route exact path='cart' element={<Cart />} />
                <Route exact path='checkout' element={<Checkout />} />
                <Route exact path='all/product' element={<AllProductList />} />
                <Route exact path='login' element={<Login />} />
                <Route exact path='signup' element={<Signup />} />
                <Route exact path='user/deshboard/:activ' element={<UserDashboard />} />
                <Route exact path='aboutus/' element={<AboutUs />} />
                <Route exact path='about/privacy' element={<Privacy />} />
                <Route exact path='about/shipping' element={<Shipping />} />
                <Route exact path='about/refund' element={<Refund />} />
                <Route exact path='about/terms/condition' element={<TermsCondition />} />
                <Route exact path='contact/us' element={<ContactUs />} />
                <Route exact path='all/brands' element={<Brands />} />
                <Route exact path='all/category' element={<AllCategoris />} />
                <Route exact path='faq/' element={<Faq />} />
                <Route exact path='policy' element={<PolicyCookie />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
};

export default Routers;