import React, { useState, useEffect, memo } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import Helmet from "../components/Helmet/Helmet";
import "../styles/home.css";
import { useStateContext } from "../contexts/ContextProviders";

import { Col, Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import ProductsList from "../components/UI/ProductsList";
import Top10Card from "../components/UI/ToptenCard";
import HeroSlider from "../components/Element/Slider/HeroSlider";
import Categories from "../components/Categories/Categories";
import serviceData from "../assets/data/serviceData";
import ProductCard from "../components/UI/ProductCard";
import axiosClient from "../axios-client";
import { getCurrencydata, shippingCost } from "../redux/slices/settingSlice";
import GeneralProductList from "../components/UI/GeneralProductList";
import { useQuery } from "react-query";
import CategoriesBanner from "../components/UI/CategoriesBanner";
import { Skeleton } from "@mui/material";
import addBanner from "../assets/images/home-v5-banner.png";
import catBanner1 from "../assets/images/cat-banner-1.jpg";
import catBanner2 from "../assets/images/cat-banner-2.jpg";
import catBanner3 from "../assets/images/cat-banner-3.jpg";

const Home = () => {
  const { lang } = useStateContext();

  const [trendingProducts, settrendingProducts] = useState([]);
  const [bestSalesProducts, setbestSalesProducts] = useState([]);
  const [popularProdutcs, setpopularProdutcs] = useState([]);
  const [newArrivalProduct, setnewArrivalProduct] = useState([]);
  const [isrender, setIsRender] = useState(true);
  const [offerBanner, setOfferBanner] = useState([]);
  const [category, setCategoryList] = useState([]);

  const year = new Date().getFullYear();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const basepath = useSelector((state) => state.setting.basepath);
  const bannerlink = useQuery(
    "bannerlink",
    async () => {
      try {
        const response = await axiosClient.get("featured/link/list");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );

  const offerBannerdata = useQuery(
    "myDatas",
    async () => {
      try {
        const response = await axiosClient.get("offer/banner");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );
  const categoryListdata = useQuery(
    "category",
    async () => {
      try {
        const response = await axiosClient.get(`product/category?lang=${lang}`);
        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  );
  const trendingListdata = useQuery(
    "trending",
    async () => {
      try {
        const response = await axiosClient.get("home/trending/product/get");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );
  const bestsellListdata = useQuery(
    "bestsell",
    async () => {
      try {
        const response = await axiosClient.get("home/best/sell/product");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );
  const newarrivaldata = useQuery(
    "newarrival",
    async () => {
      try {
        const response = await axiosClient.get("home/new/arrival/product");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );
  const populardata = useQuery(
    "popular",
    async () => {
      try {
        const response = await axiosClient.get("home/popular/product/get");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );
  const popularcategory = useQuery(
    "popularCategory",
    async () => {
      try {
        const response = await axiosClient.get(`product/popular/category?lang=${lang}`);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );
  const popularbrand = useQuery(
    "popularBrand",
    async () => {
      try {
        const response = await axiosClient.get("product/top/brand");
        return response.data;
      } catch (error) {
        console.error(error);
      }
    }
  );

  useQuery("currency", () => dispatch(getCurrencydata()));

  return (
    <Helmet title={"Home"}>
      {/* Hero area Section */}
      {/* {offerBanner.length>0&&<HeroSlider offerBanner={offerBanner} />} */}
      <section className="hero__area">
        <Container className="paddingsst">
          <Row>
            <Col lg="3" md="4" sm="4">
              {categoryListdata?.isLoading == false ? (
                <Categories category={categoryListdata?.data ?? ''} />
              ) : (
                <div>
                  <Skeleton
                    variant="rectangular"
                    className="mt-3 loadcategory"
                  />
                </div>
              )}
            </Col>
            <Col lg="9" md="8" sm="8">
              {offerBannerdata?.isLoading == false ? (
                <HeroSlider offerBanner={offerBannerdata?.data ?? ''} />
              ) : (
                <div>
                  <Skeleton
                    variant="rectangular"
                    className="mt-3 loadslidertop"
                  />
                </div>
              )}

              {popularcategory?.isLoading == false ? (
                <CategoriesBanner categorydata={popularcategory?.data ?? ''} />
              ) : (
                <div>
                  <Skeleton
                    variant="rectangular"
                    className="mt-3 loadslidertop"
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* categories wise featureSection */}
      <section className="categories_feature">
        <Container className="paddingsst">
          <div className="cate_wise_feature">
            { bannerlink && bannerlink?.isLoading == false &&
              bannerlink.data && typeof(bannerlink.data) == 'object' &&
              bannerlink.data.map((banner_link, index) => {
                return (
                  <div className="cate_feature" key={index}>
                    <a
                      href={banner_link.link}
                      target="_blank"
                      style={{ width: "100%" }}
                    >
                      <img
                        src={`${basepath}/${banner_link.image}`}
                        className="img-fluid"
                        alt="counterImg"
                      />
                    </a>
                  </div>
                );
              })}
          </div>
        </Container>
      </section>

      {/* Trending Products Section */}
      <section className="trending__products">
        <Container>
          <Row className="productlistCard">
            <Col lg="12" md="12" sm="12">
              <div className="d-flex justify-content-between p-2 mb-2 pb-2 btmborder__st">
                <div className="section__title">
                  <h3> {t("trending_products")} </h3>
                </div>
                <div className="section_product">
                  {/* <Link to='/product/list/none/none/trending/0' > View More </Link> */}
                  <Link to={`/products/list/0/0/trending/0/0`}>
                    {" "}
                    {t("view_more")}{" "}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg="12" md="12" sm="12">
              { trendingListdata && trendingListdata?.isLoading == false && (
                <ProductsList data={trendingListdata?.data ?? ''} />
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Best Sales Products Section */}
      <section className="best__sales">
        <Container>
          <Row className="productlistCard">
            <Col lg="12" md="12">
              <div className="d-flex justify-content-between p-2 mb-2 pb-2 btmborder__st">
                <div className="section__title">
                  <h3> {t("best_sales")} </h3>
                </div>
                <div className="section_product">
                  {/* <Link to='/product/list/none/none/bestSell/0' > View More </Link> */}
                  <Link to={`/products/list/0/0/bestSell/0/0`}>
                    {" "}
                    {t("view_more")}{" "}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg="12" md="12" sm="12">
              {/* <ProductsList
                        data={bestSalesProducts}
                    />  */}
              { bestsellListdata && bestsellListdata?.isLoading == false && (
                <GeneralProductList data={bestsellListdata?.data ?? ''} />
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Add Banner Section */}
      {/* <section className="add__banner">
        <Container className="paddingsst">
          <Row className="align-items-center">
            <Col lg="12" md="12">
              <div className="addbanner_card">
                <img src={addBanner} className="img-fluid" alt="counterImg" />
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      {/* New Arrivals Section */}
      <section className="new__arrivals">
        <Container>
          <Row className="productlistCard">
            <Col lg="12">
              <div className="d-flex justify-content-between p-2 mb-2 pb-2 btmborder__st">
                <div className="section__title">
                  <h3> {t("new_arrivals")} </h3>
                </div>
                <div className="section_product">
                  {/* <Link to='/product/list/none/none/newArrival/0' > View More </Link> */}
                  <Link to={`/products/list/0/0/newArrival/0/0`}>
                    {" "}
                    {t("view_more")}{" "}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg="12">
              {/* <ProductsList data={newArrivalProduct}/> 
                  <ProductsList data={newArrivalProduct} /> */}

              { newarrivaldata && newarrivaldata?.isLoading == false && (
                <GeneralProductList data={newarrivaldata?.data ?? ''} />
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Popular Product Section */}
      <section className="popular_product">
        <Container>
          <Row className="productlistCard">
            <Col lg="12">
              <div className="d-flex justify-content-between p-2 mb-2 pb-2 btmborder__st">
                <div className="section__title">
                  <h3> {t("popular_product")} </h3>
                </div>
                <div className="section_product">
                  {/* <Link to='/product/list/none/none/popular/0' > View More </Link> */}
                  <Link to={`/products/list/0/0/popular/0/0`}>
                    {" "}
                    {t("view_more")}{" "}
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg="12">
              { populardata && populardata?.isLoading == false && (
                <ProductsList data={populardata?.data ?? ''} />
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Top 10 Categories/Brands Section */}
      <section className="top_10_area mb-4">
        <Container className="paddingsst">
          <Row className="py-3">
            <Col lg="6" md="12">
              <div className="d-flex justify-content-between p-2 mb-2 pb-2 btmborder__st">
                <div className="section__title">
                  <h3> {t("top_ten_categories")} </h3>
                </div>
                <div className="section_product">
                  <Link to="/all/category"> {t("view_all_categories")} </Link>
                </div>
              </div>
              <div className="row">
                { popularcategory &&
                popularcategory?.isLoading == false &&
                popularcategory?.data ?
                typeof(popularcategory.data) == 'object' &&
                  popularcategory?.data.map((data, index) => {
                    return (
                      <div className="col-lg-6 col-md-6" key={index}>
                        <Link
                          to={`/products/list/${data.id}/0/0/0/0`}
                          className="w-100"
                        >
                          <Top10Card
                            image={`${basepath}/${data.image}`}
                            name={`${data.name}`}
                            type="category"
                            category_id={data.id}
                          />
                        </Link>
                      </div>
                    );
                  }): (
                    <p className="p-4">There are no item.</p>
                  )}

                {/* <div className='col-lg-6 col-md-6'>
                  <Top10Card />
                </div> */}
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="d-flex justify-content-between p-2 mb-2 pb-2 btmborder__st">
                <div className="section__title">
                  <h3> {t("top_ten_brands")} </h3>
                </div>
                <div className="section_product">
                  <Link to="/all/brands"> {t("view_all_brands")} </Link>
                </div>
              </div>
              <div className="row">
                { popularbrand &&
                popularbrand.isLoading == false &&
                popularbrand.data ?
                typeof(popularbrand.data) == 'object' &&
                  popularbrand.data.map((data, index) => {
                    return (
                      <div className="col-lg-6 col-md-6" key={index}>
                        <Link
                          to={`/all/brands`}
                          className="w-100"
                        >
                          <Top10Card
                            image={`${basepath}/${data.image_path}`}
                            name={`${data.name}`}
                            type="category"
                            brand_id={data.id}
                          />
                        </Link>
                      </div>
                    );
                  }): (
                    <p className="p-4">There are no item.</p>
                  )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
