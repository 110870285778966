import React, { useEffect} from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import { useParams } from 'react-router-dom';
import AboutImg from '../assets/images/img/Ads-01-01.jpg';
import '../styles/about.css';
import OrderInfo from '../components/UI/OrderInfo';
import { useTranslation } from 'react-i18next';


const AboutUs = () => {
    const { t } = useTranslation();
    const aboutUs = JSON.parse(localStorage.getItem('COMPANY_INFO')).aboutUs;
    useEffect(()=>{
        window.scrollTo(0, 0);
    })
    // {aboutUs}
    return (
        <Helmet title={t("about_us")}>
            <CommonSection title={t("about_us")}/>
            <div className="container p-md-5 main-content">
                <div className="row p-md-5 g-5 d-flex align-items-center">
                    <div className="col-lg-12 col-md-12">
                        <p className="lead">{aboutUs}</p>
                    </div>
                </div>
                <div className="row py-md-5">
                    <OrderInfo/>
                </div>
            </div>
        </Helmet >
    )
};

export default AboutUs;