import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Form, FormGroup, Input, Label } from 'reactstrap';
import CommonSection from '../components/UI/CommonSection'
import '../styles/shop.css';
import { Col, Container, Row } from 'reactstrap';
import axiosClient from '../axios-client';
import ProductSection from '../components/UI/ProductSection';
import { UncontrolledCollapse } from 'reactstrap';
import "../styles/allproduct.css";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useParams } from 'react-router-dom';
import { BiReset } from "react-icons/bi";
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useStateContext } from "../contexts/ContextProviders";


const AllProductList = () => {
    const { lang } = useStateContext();
    const {category_id,subcategory_id,type,offer}=useParams()
    const categoryPrm=category_id==undefined?0:category_id;
    const subCategoryPrm=category_id==undefined?0:subcategory_id;
    const typeprm=type==undefined?0:type;
    const [productlist, setProductList] = useState([]);
    const [categorySubcategory, setcategorySubcategory] = useState([]);
    const [sizelist, setSizeList] = useState([]);
    const [colorlist, setColorList] = useState([]);
    const [brandlist, setBrandList] = useState([]);
    const [category, setcategory] = useState(categoryPrm);
    const [subcategory, setsubcategory] = useState(subCategoryPrm);
    const [typey, settype] = useState(typeprm);
    const [selectColor, setselectColor] = useState('0');
    const [selectSize, setselectedSize] = useState('0');
    const [srcarr, setsrcarr] = useState({});
    const [path, setpath] = useState(window.location.pathname);
    const { t } = useTranslation()

    // const categorySubcategory = useQuery('categoryData', async () => await axiosClient.get('/all/category/subcategory').then(({ data }) => data));

    // console.log(categorySubcategory)

    const subcategorylist = useQuery('subcategory', async () => await axiosClient.get(`all/category/subcategory?lang=${lang}`).then(({ data }) => data).catch((error) => { console.error(error); }));

    useEffect(() => {
        reset()
        try {axiosClient
            .get(`all/category/subcategory?lang=${lang}`)
            .then(({ data }) => {
                setcategorySubcategory(data);
            }).catch((error) => { console.error(error); });

        axiosClient
            .get(`product/all/size?lang=${lang}`)
            .then(({ data }) => {
                let allSizelist = []; 
                try{
                    data.map(sizelist => {
                        let size = {
                            id: sizelist.id,
                            name: sizelist.size
                        };
                        allSizelist.push(size);
                    })
                }catch(e){
                    console.error(e)
                }
                allSizelist = [...new Set(allSizelist)];
                setSizeList(allSizelist);
            }).catch((error) => { console.error(error); });

        axiosClient
            .get(`product/all/color?lang=${lang}`)
            .then(({ data }) => {
                let allColorlist = [];
                try {
                    data.map(colorlist => {
                        let color = {
                            id: colorlist.id,
                            name: colorlist.name,
                            code: colorlist.color_code
                        };
                        allColorlist.push(color);
                    })
                }catch (e) {
                    console.error(e)
                }
                allColorlist = [...new Set(allColorlist)];
                setColorList(allColorlist);
            }).catch((error) => { console.error(error); });

        axiosClient
            .get(`product/all/brand`)
            .then(({ data }) => {
                let allBrandlist = [];
                try {
                    data.map(brandlist => {
                        let brand = {
                            id: brandlist.id,
                            name: brandlist.name
                        }
                        allBrandlist.push(brand);
                    })
                }catch (e) {
                    console.error(e)
                }
                allBrandlist = [...new Set(allBrandlist)];
                setBrandList(allBrandlist);
            }).catch((error) => { console.error(error); });

        } catch (e) {
            console.error(e);
        }
    }, [path,selectSize])

    useEffect(()=>{

    },[productlist])




    function valuetext(value) {
        return `${value}°C`;
    }

    const minDistance = 10;


    const [value1, setValue1] = React.useState([0, 40000]);

    const handleChange1 = (
        event,
        newValue,
        activeThumb,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
        } else {
            setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
        }

    };

    const categoryProduct = (category_id) => {
        setcategory(category_id);
        setsubcategory(0);
    }

    const reset = () =>  {
        setselectedSize(0)
        setselectColor(0)
        let srcdatalist = {
            min: value1[0],
            max: value1[1],
            category_id: category,
            sub_category_id: subcategory,
            color: 0,
            size: 0,
            type:typey,
        }
        axiosClient
            .post("product/price/range/src", srcdatalist)
            .then(({ data }) => {
                setProductList(data);
            }).catch((error) => { console.error(error); });

    }

    const subCategoryProduct = (subCategory_id) => {
        setsubcategory(subCategory_id)
    }
    useEffect(() => {
        getrangewiseproduct()
    }, [selectColor, selectSize, category, subcategory])

    useEffect(() => {
        let srcproduct = setTimeout(() => {
            getrangewiseproduct();

        }, 500);
        return () => clearTimeout(srcproduct)
    }, [value1])

    const getrangewiseproduct = () => {
        let srcdatalist = {
            min: value1[0],
            max: value1[1],
            category_id: category,
            sub_category_id: subcategory,
            color: selectColor,
            size: selectSize,
            type:typey,
        }
        axiosClient
            .post("product/price/range/src", srcdatalist)
            .then(({ data }) => {
                setProductList(data);
            }).catch((error) => { console.error(error); });
    }

    return (
        <Helmet title='Shop'>
            {/* <CommonSection title={'Products'}/> */}
            <Container>
                <Row>
                    <Col lg="3" md="3">
                        <div className="main-products-wrapper">
                            <div className='main-products'>
                                <section className='new__arrivals'>
                                    <Container className={'main-content'}>
                                        <Row className=''>
                                            <Col lg='12' className='srcdiv'>
                                                <div>
                                                    <h4 className='sideitemtxt'>{t("category")}  <span onClick={reset} className='reseticon'><BiReset/></span></h4>
                                                </div>
                                                <ul>
                                                    {                           
                                                        subcategorylist && subcategorylist?.isSuccess && subcategorylist?.data && typeof(subcategorylist?.data) == 'object' && subcategorylist?.data.map(cate => {
                                                            return <div key={cate.id}>
                                                                <li>
                                                                    <button className='category_name' id={`cate${cate.id}`} onClick={() => categoryProduct(cate.id)}>{cate.name}</button>
                                                                </li>
                                                                <UncontrolledCollapse toggler={`#cate${cate.id}`} className={cate.id==category&&'show'}>
                                                                    {
                                                                        cate.subcategory.map(sub => {
                                                                            return <li key={sub.id} className="sub_category_name "  onClick={() => subCategoryProduct(sub.id)}>-<span className='testbd'>{sub.name}</span> </li>
                                                                        })
                                                                    }
                                                                </UncontrolledCollapse>
                                                            </div>
                                                        })
                                                    }
                                                </ul>
                                            </Col>
                                            {/* <Col lg='12' className='srcdiv'>
                                                <h4 className='sideitemtxt'>{t("price")} {t("range")}</h4>
                                                <Slider
                                                    min={0}   // Minimum value
                                                    max={10000} // Maximum value
                                                    step={50}  // Incremental step
                                                    getAriaLabel={() => 'Minimum distance'}
                                                    value={value1}
                                                    onChange={handleChange1}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={valuetext}
                                                    disableSwap
                                                    sx={{
                                                        '& > span':{color: '#2eb242!important'}
                                                    }}
                                                />
                                                <div className='pricerangeset'>
                                                    <span>{t("min")}: {value1[0]}</span>
                                                    <span>{t("max")}: {value1[1]}</span>

                                                </div>
                                            </Col> */}
{/* 
                                            <Col className='srcdiv'>
                                                <h4 className='sideitemtxt'>{t("size")}</h4>
                                                <div className='colorsizeheight'>
                                                    <FormGroup check>
                                                        <Input
                                                            name="size"
                                                            id="size"
                                                            type="radio"
                                                            onChange={() =>  setselectedSize('0') }
                                                            defaultChecked={ selectSize == '0' ? true : false }
                                                        />
                                                        <Label check className='category_name' htmlFor='size'>
                                                            All
                                                        </Label>
                                                    </FormGroup>
                                                    {
                                                        sizelist && sizelist.map(size => {
                                                            return (
                                                                <FormGroup check key={size.id}>
                                                                    <Input
                                                                        name="size"
                                                                        id="size"
                                                                        type="radio"
                                                                        onChange={() => {size.id == selectSize ? setselectedSize('0') : setselectedSize(size.id)}}
                                                                        defaultChecked={size.id==selectSize?true:false}
                                                                    />
                                                                    <Label check className='category_name' htmlFor='size'>
                                                                        {size.name}
                                                                    </Label>
                                                                </FormGroup>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg='12' sm="12" className='srcdiv'>
                                                <h4 className='sideitemtxt'>{t("color")}</h4>
                                                <div className='colorsizeheight'>
                                                    < FormGroup check >
                                                        <Input
                                                            name="color"
                                                            id="color"
                                                            type="radio"
                                                            onChange={() => setselectColor('0')}
                                                            defaultChecked={ selectColor == '0' ? true : false }
                                                        />
                                                        <Label check htmlFor='color'>
                                                            <span> All </span>
                                                        </Label>
                                                    </FormGroup>
                                                    {
                                                        colorlist && colorlist.map(color => {
                                                            return (
                                                                < FormGroup check key={color.id}>
                                                                    <Input
                                                                        name="color"
                                                                        id="color"
                                                                        type="radio"
                                                                        onChange={() => {color.id == selectColor ? setselectColor('0') : setselectColor(color.id)}}
                                                                        defaultChecked={color.id==selectColor?true:false}
                                                                    />
                                                                    <Label check htmlFor='color'>
                                                                        <div className='colorcodeset' style={{ background: color.code, display: 'inline-block' }}></div>
                                                                        <span> {color.name} </span>
                                                                    </Label>
                                                                </FormGroup>
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </Col> */}
                                        </Row>
                                    </Container>
                                </section>
                            </div>
                        </div>
                    </Col>

                    <Col lg="9" md="9">
                        <Row className='product_sort_Card '>
                            <Col lg='2' md='6'>
                                <div className="shop__title">
                                    <h3>{t("products")}</h3>
                                </div>
                            </Col>
                            {/* <Col lg='3' md='6'>
                                <div className='asds_by'>
                                    <select className="form-select" style={{backgroundPosition: "right 0.2rem center", padding: "0.34rem 0.7rem"}} aria-label="Default select example">
                                        <option> {t("default")} </option>
                                        <option>{t("name")} ({t("a")} - {t("z")})</option>
                                        <option>{t("name")} ({t("z")} - {t("a")})</option>
                                        <option>{t("price")} ({t("low")} &gt; {t("high")})</option>
                                        <option>{t("price")} ({t("high")} &gt; {t("low")})</option>
                                        <option>{t("rating")} ({t("highest")})</option>
                                        <option>{t("rating")} ({t("lowest")})</option>
                                        <option>{t("model")} ({t("a")} - {t("z")})</option>
                                        <option>{t("model")} ({t("z")} - {t("a")})</option>
                                        <option>{t("out_of_stock")}</option>
                                        <option>{t("in_stock")}</option>
                                    </select>
                                </div>
                            </Col>
                            <Col lg='3' md='4'>
                                <div className='sort_by'>
                                    <select className="form-select" style={{backgroundPosition: "right 0.2rem center", padding: "0.34rem 0.7rem"}} aria-label="Default select example" defaultValue="0">
                                        <option value="0"> {t("sort_by")} </option>
                                        <option value="1"> {t("lowest")} </option>
                                        <option value="1"> {t("oldest")} </option>
                                        <option value="2">{t("price")} {t("low")} {t("to")} {t("high")} </option>
                                        <option value="3">{t("price")} {t("high")} {t("to")} {t("low")} </option>
                                    </select>
                                </div>
                            </Col> */}
                            {/* <Col lg='2' md='4'>
                                <div className='brands_by'>
                                    <select className="form-select" style={{backgroundPosition: "right 0.2rem center", padding: "0.34rem 0.7rem"}} aria-label="Default select example" defaultValue="0">
                                        <option value="0">{t("all")} {t("brands")} </option>
                                        {
                                            brandlist && brandlist.map( brand => {
                                                return (
                                                    <option key={brand.id} value={`${brand.id}`}> {brand.name} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </Col> */}
                            {/* <Col lg='2' md='4'>
                                <div className='brands_by'>
                                    <select className="form-select" style={{backgroundPosition: "right 0.2rem center", padding: "0.34rem 0.7rem"}} aria-label="Default select example" defaultValue="0">
                                        <option value="0"> {t("show")} 20 </option>
                                        <option value="1">{t("show")} 30  </option>
                                        <option value="2">{t("show")} {t("all")}</option>
                                    </select>
                                </div>
                            </Col> */}
                        </Row>
                        <Row className='all_product_Card'>
                            <Col lg='12' md='12'>
                                <ProductSection data={productlist} />
                            </Col>
                        </Row>
                        
                        <Row className='product_pagination_Card'>
                            <div className="col-md-12">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination float-end">
                                        <li className="page-item"><a className="page-link link" href="#">{t("previous")}</a></li>
                                        <li className="page-item"><a className="page-link link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link link" href="#">3</a></li>
                                        <li className="page-item"><a className="page-link link" href="#">{t("next")}</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </Row>
                        
                    </Col>
                </Row>
               
            </Container>
        </Helmet >
    )
};

export default AllProductList;