import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

const Helmet = (props) => {
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(localStorage.getItem('cookie'));
  const setCookie = (e) => {
    e.preventDefault();
    setIsValid(true);
    localStorage.setItem('cookie', true);
  }
  if(props.title) document.title = "Biomaschine-" + props.title;
  else document.title = "Biomaschine";
  return (
    <>
      <div className='w-100'>{props.children}</div>
      {
        !isValid && (
          <div className='cookie-modal'>
            <p>Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für die Geschäftsleitung für folgenden Herausgeber dieser Website: B I O M A S C H I N E.DE

Eine Nutzung der Internetseiten der Biomaschine ist grundsätzlich ohne jede Angabe personenbezogener Daten möglich. <a href='' onClick={() => navigate('/policy')}>Read more...</a></p>
            <div>
              <button onClick={setCookie} style={{marginRight: '10px'}}>Accept</button>
              <button onClick={() => {setIsValid(true)}}>Cancel</button>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Helmet