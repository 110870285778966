import React from "react";
import { Col, Container, Form, FormGroup, Row, Input } from "reactstrap";

import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { AiOutlineCreditCard } from "react-icons/ai";
import stripeimg  from '../../assets/images/img/stripe.png'
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axiosClient from "../../axios-client";
import { cartActions } from "../../redux/slices/cartSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CashOnDelivery = (props) => {
    const totalQty = useSelector((state) => state.cart.totalQuantity);
    const cartItems = useSelector((state) => state.cart.cartItems);

    const shippingCost = useSelector((state) => state.setting.shippingCost);
    const totalAmountData = useSelector((state) => state.cart.totalAmount);
    const totalAmount = (parseFloat(totalAmountData * 119 / 100 ) + parseFloat(shippingCost)).toFixed(2);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation()

    const cashonDelivery=()=>{
        let type={
            type:'cashonDelivery',
        }
        props.payment(type)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
            var data={
                'payment_type':'cashOnDelivery',
                'shipping_info':props.shippingInfo,
                'payment_info': '',
                'order_item':cartItems,
                'total_payable':totalAmount,
                'shippingCost':shippingCost,
            }


            axiosClient
            .post('cashOnDelivery/payment', data)
            .then(({ data }) => {
                if(data.status==331){                   
                    toast.error(data.msg ?? 'Internal error',{
                        theme: "colored",
                        });
                        dispatch(cartActions.deleteItem(data.id));
                    
                }
                if(data.status==400){
                    toast.error(data.msg ?? 'Internal error',{
                        theme: "colored",
                        });
                        dispatch(cartActions.removeCartAllItem());
                }

                if(data.status==200){
                    toast.success(data.msg,{
                        theme: "colored",
                        });
                        dispatch(cartActions.removeCartAllItem());
                }
                if(data.status==404) {
                    toast.error(`${t('failed_pay')}`,{
                        theme: "colored",
                        });
                }
                navigate("/all/product");
            }).catch((err) => {
                toast.error('Internal error' ,{
                    theme: "colored",
                    });
                console.log(err);
            });
    };



    return <div>
                <h6>{t("paypal")}</h6>
                <br />
                <h6>{t("order_confirm")}:{totalAmount}</h6>

                <div><button className="buy__btn w-100" onClick={handleSubmit}><span className="import_white"> {t("order_confirm")} </span></button></div>
            </div>

}
export default CashOnDelivery;