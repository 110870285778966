import React from 'react'
import ProductCard from './ProductCard';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import '../../styles/general-product-list.css';
import { AiOutlineCreditCard,AiOutlineSafety,AiOutlineCalendar} from "react-icons/ai";
import { BsHeadset,BsTruck} from "react-icons/bs";
import "../../styles/order_info.css"



const OrderInfo = () => {

    const { t } = useTranslation()

    return (
        <section className="features-wrap">
            <div className="container main-content">
                <div className="features">
                    <div className="feature-list">
                        <div className="single-feature">
                            <div className="feature-icon">
                               <BsHeadset className='iconColor'/>
                            </div>
                            <div className="feature-details">
                                <h6>24/7 {t("support")}</h6>
                                <span>{t("support_every_time")}</span>
                            </div>
                        </div>
                        <div className="single-feature">
                            <div className="feature-icon">
                                <AiOutlineCreditCard className='iconColor'/>
                      
                            </div>
                            <div className="feature-details">
                                <h6>{t("accept")}</h6>
                                <span>{t("visa")}, {t("paypal")}, {t("master")}</span>
                            </div>
                        </div>
                        <div className="single-feature">
                            <div className="feature-icon">
                            <AiOutlineSafety className='iconColor'/>
                        
                            </div>
                            <div className="feature-details">
                                <h6> {t("payment_method")} </h6>
                                <span>100% {t("secured")}</span>
                            </div>
                        </div>
                        <div className="single-feature">
                            <div className="feature-icon">
                            <BsTruck className='iconColor'/>
                          
                            </div>
                            <div className="feature-details">
                                <h6>{t("free_shipping")}</h6>
                                <span>{t("order_over")} $100</span>
                            </div>
                        </div>
                        <div className="single-feature" style={{borderRight:"none"}}>
                            <div className="feature-icon">
                            <AiOutlineCalendar className='iconColor'/>
                            </div>
                            <div className="feature-details">
                                <h6>{t("days_return")}</h6>
                                <span>30 {t("days_guarantee")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default OrderInfo