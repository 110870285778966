import React, { useEffect,} from 'react'
import Helmet from '../../components/Helmet/Helmet';
import CommonSection from '../../components/UI/CommonSection';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const TermsCondition = () => {
    const termsCondition = JSON.parse(localStorage.getItem('COMPANY_INFO')).privacy;
    const { t } = useTranslation();

    useEffect(()=>{
        window.scrollTo(0, 0);
    })
   
    return (
        <Helmet title='Shop'>
            <CommonSection title={t("terms_conditions")}/>
            <div className='container main-content'> 
                <div className='row my-3'>
                    <div className="col-md-12">
                        <div className="p-5 bg-white my-2 rounded border">
                           <p> {termsCondition}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Helmet >
    )
};

export default TermsCondition;