import React, { useEffect, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  AiOutlineProfile,
  AiOutlineUser,
  AiOutlineShoppingCart,
  AiOutlineUnlock,
} from "react-icons/ai";
import UserProfile from "./UserProfile";
import UserAddress from "./UserAddress";
import UserOrderList from "./UserOrderLIst";
import ChangePassword from "./ChangePassword";
import { useTranslation } from 'react-i18next';

const UserDashboard = () => {
  let { activ } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loginState, setLoginState] = useState(useSelector((state) => state.user.isLogin));
  const [activeItem, setactiveItem] = useState(activ);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
  };

  const submitForm = () => {
    console.log(email + " " + password);
  };

  let order = () => {
    setactiveItem(3)
  }

  useEffect(() => {
    if(!loginState) navigate('/login');
  }, [])

  let activeitem = (activeItem == 1) && <UserProfile orderlist={order} /> || (activeItem == 2) && <UserAddress /> || (activeItem == 3) && <UserOrderList /> || (activeItem == 4) && <ChangePassword />

  return (
    <Helmet title="Login">
      <section>
        <Container className={'main-content'}>
          <Row className="dashboardCard py-3 ">
            <Col lg="3" className="login-feture py-3 cardleft">
              <ul className="card__ul">
                <li className="dashboard_item" onClick={() => setactiveItem(1)}>
                  <span className="dashboard_item__icon">
                    <AiOutlineUser />
                  </span>
                  {t("profile")}
                </li>
                <li className="dashboard_item" onClick={() => setactiveItem(2)}>
                  <span className="dashboard_item__icon">
                    <AiOutlineProfile />
                  </span>
                  {t("information")}
                </li>
                <li className="dashboard_item" onClick={() => setactiveItem(3)}>
                  <span className="dashboard_item__icon" >
                    <AiOutlineShoppingCart />
                  </span>
                  {t("my_order")}
                </li>
                <li className="dashboard_item" onClick={() => setactiveItem(4)}>

                  <span className="dashboard_item__icon">
                    <AiOutlineUnlock />
                  </span>
                  {t("change_password")}
                </li>
              </ul>
            </Col>
            <Col lg="8" className="login-feture py-5 cardrigt custom-height">
              {
                activeitem
              }

            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default UserDashboard;
