import React,{useState} from 'react';
import Helmet from '../components/Helmet/Helmet';
import { Link,useHistory,useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap';
import '../styles/authentications.css';
import axiosClient from '../axios-client';
import { toast } from "react-toastify";
import { userAction } from '../redux/slices/userSlice';
import { useTranslation } from 'react-i18next';


const Signup = () => {

  const [username,setUserName] = useState();
  const [email,setEmail] = useState();
  const [password,setPassword] = useState();
  const [passwordConfirmation,setpasswordConfirmation] = useState();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const { t } = useTranslation();

  const submitregForm = (e) =>{
    e.preventDefault()
    var data={
      name:username,
      email:email,
      password:password,
      password_confirmation:passwordConfirmation
    }
    axiosClient
    .post('/signup',data)
    .then(({ data }) => {

      if(data.status==422){
        console.log(data.msg)
        toast.error(`${t(data.msg)}`,{theme: "colored"});
       }
       if(data.status==200){
        let infodata={
          token:data.token,
          userInfo:data.user,
        }
        dispatch(userAction.setLoginInfo(infodata));
        toast.success(`${t("successfully_regist")}`)
        navigate('/home')
        
       }


       localStorage.setItem('ACCESS_TOKEN',data.token)
       localStorage.setItem('USER_DATA',JSON.stringify(data.user))
    });
  }

  return (
    <Helmet title="Signup">
        <section>
            <Container>
              <Row>
                <Col lg='12' className='login-feture py-5'>
                    <h3 className='fw-bold fs-4 mb-4 text-center' style={{textTransform: "capitalize"}}> {t("signup")} </h3>
                    <div className='d-flex justify-content-center'>
                      <Form className='auth__form' onSubmit={(e)=>submitregForm(e)}>
                          <FormGroup className='form__group'>
                              <Input 
                                type="text"
                                required
                                placeholder={`${t("enter_your_name")}`}
                                defaultValue={username}
                                onChange={(e) => setUserName(e.target.value)}
                                autoComplete='false'
                              />
                          </FormGroup>
                          <FormGroup className='form__group'>
                              <Input 
                                type="email"
                                placeholder={`${t("enter_your_email")}`}
                                defaultValue={email}
                                onChange={(e)=> setEmail(e.target.value)}
                                autoComplete='false'
                              />
                          </FormGroup>
                          <FormGroup className='form__group'>
                            <Input 
                              type="password" 
                              placeholder={`${t("enter_your_password")}`}
                              value={password} 
                              minLength={6}
                              defaultValue={''}
                              onChange={(e)=> setPassword(e.target.value)} 
                              autoComplete='false'
                            />
                          </FormGroup>

                          <FormGroup className='form__group'>
                            <Input 
                              type="password" 
                              placeholder={`${t("confirm_password")}`}
                              value={passwordConfirmation} 
                              minLength={6}
                              defaultValue={''}
                              onChange={(e)=> setpasswordConfirmation(e.target.value)} 
                              autoComplete='false'
                            />
                          </FormGroup>

                          <FormGroup className='form__group'>
                              <button type='submit' className="auth__btn"> {t("create_an_account")}</button>
                          </FormGroup>
                          <p className='account_state'> 
                              {t("already_have_an_account")}? {"  "}
                              <Link className= 'text-black fw-bold' style={{textTransform: "capitalize"}} to="/login"> {t("signin")} </Link>
                          </p>
                      </Form>
                    </div>
                </Col>
              </Row>
            </Container>
        </section>
    </Helmet>
  )
}

export default Signup;