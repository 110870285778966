import React, { useEffect, useState } from "react";
import Helmet from "../Helmet/Helmet";
import CommonSection from "../UI/CommonSection";
import { useTranslation } from "react-i18next";
import axiosClient from '../../axios-client';
import ProductSection from '../UI/ProductSection';
import { Col, Row } from 'reactstrap';


function Brands() {
  const [bestOfferList, setbestOfferListt] = useState([]);
  const { t } = useTranslation();
  useEffect(()=> {
    axiosClient
      .get("product/best/offers")
      .then(({ data }) => {
        setbestOfferListt(data);
      }).catch((error) => { console.error(error); });
  }, [])
  return (
    <Helmet title={t("top_offers")}>
      <CommonSection title={t("top_offers")} />

      <div className="container main-content">
        <div>
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="p-4 bg-white my-2 rounded border">
                <div>
                  <h2> {t("top_offers")} </h2>
                </div>
              </div>
            </div>
          </div>

          <Row className="custom-height">
            {
              bestOfferList.length > 0 ? (
                  <Col lg='12' md='12'>
                      <ProductSection data={bestOfferList} />
                  </Col>
              ) : (
                <p>{t('There are no any offers')}.</p>
              )
            }
          </Row>

        </div>
      </div>
    </Helmet>
  );
}

export default Brands;
