import React, { useEffect, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";

import {
  AiOutlineHeart,
  AiOutlineShopping,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getWishcount } from "../../redux/slices/settingSlice";
import { userAction } from '../../redux/slices/userSlice';
import { useTranslation } from 'react-i18next';
const UserProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.user.userInfo);
  const wishcount = useSelector((state) => state.setting.wishcount);
  const totalcart = useSelector((state) => state.cart.totalQuantity);
  const [userName, setUserName] = useState(userinfo.length > 0 ? (JSON.parse(userinfo).name ?? '') : '');
  const [emailAddress, setEmailAddress] = useState(userinfo.length > 0 ? (JSON.parse(userinfo).email ?? '') : '');
  const [phoneNumber, setPhoneNumber] = useState(userinfo.length > 0 ? (JSON.parse(userinfo).phone ?? '') : '');
  const [currency, setCurrency] = useState(userinfo.length > 0 ? (JSON.parse(userinfo).currency ?? 'EURO') : 'EURO');

  const setUserInfo = (event) => {
    event.preventDefault();
    const userInfo = {
      user_name: userName,
      email_address: emailAddress,
      phone_number: phoneNumber,
      currency: currency,
    };

    axiosClient
      .post('user/setUserInfo', userInfo)
      .then(({ data }) => {
        if (data.status == 200) {
          let userInfo={
            token:data.token,
            userInfo:data.user,
          }
          dispatch(userAction.setLoginInfo(userInfo));
          localStorage.setItem('ACCESS_TOKEN',data.token)
          localStorage.setItem('USER_DATA',JSON.stringify(data.user))
          toast.success(data.msg);
        }
      }).catch(
        (err) => {
          console.error(err);
        }
      );
  }

  useEffect(() => {
    dispatch(getWishcount());
  }, []);
  const orderlistshow = () => {
    props.orderlist();
  }

  return (
    <Helmet title="Login">
      <section>
        <Container>
          <Row className="dashboardCard">
            <Col lg="6" className="profileCardLeft">
              <div className="roundImg">
                <img
                  src="http://admin.biomaschine.de/assets/adminPanel/images/avatars/avatar-1.png"
                  alt=""
                />
              </div>
              <div>
                <span className="userName">{t("welcome")}</span>
                <br />
                <span style={{ textTransform: "capitalize" }}>{userName}</span>
              </div>
            </Col>
            <Col lg="6" className="profileCardRigt">
              <Button variant="contained" className="logoutbtnst">
                {t("logout")}
              </Button>
            </Col>
            <Col lg="12" className="userProfile__info_data">
              <div className="profile__main-info-item" onClick={orderlistshow}>
                <span className="roundicon">0</span>
                <AiOutlineCheckCircle className="itemi__con" />

                <span className="iconname">{t("order_done")}</span>
              </div>
              <Link to={"/product/wishlist"}>
                <div className="profile__main-info-item">
                  <span className="roundicon">{wishcount}</span>
                  <AiOutlineHeart className="itemi__con" />
                  <span className="iconname"> {t("wishlist")}</span>

                </div>
              </Link>
              <Link to={"/checkout"}>
                <div className="profile__main-info-item">

                  <span className="roundicon">{totalcart}</span>
                  <AiOutlineShopping className="itemi__con" />
                  <span className="iconname">{t("cart")}</span>

                </div>
              </Link>
            </Col>
          </Row>
        </Container>
        <form onSubmit={setUserInfo} className="mt-5 px-4">
          <Row>
            <Col lg="6" >
              <FormGroup>
                <Label
                  htmlFor="username"
                >
                  {t("name")}
                </Label>
                <Input
                  id="username"
                  className="form-control"
                  defaultValue={userName}
                  onChange={(data) => { setUserName(data.target.value) }}
                  name="username"
                  placeholder={t("name")}
                />
              </FormGroup>
            </Col>
            <Col lg="6" >
              <FormGroup>
                <Label
                  htmlFor="email"

                >
                  {t("email_address")}
                </Label>
                <Input
                  id="email"
                  defaultValue={emailAddress}
                  onChange={(data) => { setEmailAddress(data.target.value) }}
                  name="email"
                  placeholder={t("email_address")}
                  type="email"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6" >
              <FormGroup>
                <Label
                  htmlFor="phone"
                >
                  {t("phone_number")}
                </Label>
                <Input
                  id="phone"
                  className="form-control"
                  defaultValue={phoneNumber}
                  onChange={(data) => { setPhoneNumber(data.target.value) }}
                  name="phonenumber"
                  placeholder={t("phone_number")}
                  type="tel"
                />
              </FormGroup>
            </Col>
            <Col lg="6" >
              <FormGroup>
                <Label
                  htmlFor="currency"
                >
                  {t("currency")}
                </Label>
                <Input type="select" name="currency" id="currency" defaultValue={currency} onChange={(e) => setCurrency(e.target.value)} placeholder={t("currency")}>
                  <option value="EURO">EURO €</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button color="success" block type="submit" > {t("save")} </Button>
        </form>
      </section>
    </Helmet>
  );
};

export default UserProfile;
