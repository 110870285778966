import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import { t } from 'i18next'
import axiosClient from '../axios-client'

const Faq = () => {
    const [faqs, setFaqs] = useState([]);
    useEffect(() => {
        axiosClient.get("faq/list")
            .then((data) => {
                setFaqs(data.data);
            }).catch((err) => {
                console.error(err);
            })
    }, [])
    return (
        <Helmet title={t('faq')}>
            <CommonSection title={t('faq')} />
            <Container className={'main-content custom-height'}>
                <Row className='py-md-5 d-flex  justify-content-center'>
                    <div className='col-md-8'>
                        <div className="accordion" id="accordionExample">
                            {
                                faqs.length > 0 ? faqs.map((faq, index) => {
                                    return (
                                        <div className="accordion-item" key={faq.id}>
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${faq.id}`} aria-expanded="true" aria-controls={`collapse${faq.id}`}>
                                                    {faq.title}
                                                </button>
                                            </h2>
                                            <div id={`collapse${faq.id}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    {faq.details}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : (
                                    <p>There are no any FAQs.</p>
                                )
                            }
                        </div>
                    </div>
                </Row>
            </Container>
        </Helmet>
    )
}

export default Faq;