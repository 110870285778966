import React from 'react'

import { Col, Container, Row } from "reactstrap";
import { Modal, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import "../../styles/product-details.css"
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from '../../redux/slices/cartSlice';
import { toast } from "react-toastify";
import axiosClient from "../../axios-client";

import pimg from "../../assets/images/arm-chair-02.jpg"
import Carousel from 'react-multi-carousel';
import { getCurrencydata } from "../../redux/slices/settingSlice";

import { AiFillMinusCircle, AiFillPlusCircle, AiOutlineShoppingCart, AiOutlineShopping, AiFillFacebook, AiFillTwitterSquare, AiOutlineWhatsApp } from "react-icons/ai";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const ProductDetailsCart = (props) => {

    const currency_symbol = {
        'USD': '$',
        'EURO': '€',
        'UAH': '₴',
    };

    const [ usdToEuroRate, setUsdToEuroRate ] = useState(1);
    const [ usdToUahRate, setUsdToUahRate ] = useState(1);
    const [ euroToUahRate, setEuroToUahRate ] = useState(1);
    let currencyRate = 1;
    const userInfo = useSelector((state) => state.user.userInfo);
    const mainCurrency = userInfo.length > 0 ? ( JSON.parse(userInfo)['currency'] ?? 'USD' ) : 'USD';
    const dispatch = useDispatch("");
    const [id, setid] = useState(props.id);
    const url =window.location.href;
    const [offerId, setofferId] = useState(0);
    let [productDetals, setProductDetails] = useState([]);
    let [productDiscount, setProductDiscount] = useState(0);
    // let [relatedProducts, setRelatedProducts] = useState([]);
    let [productImg, setProductImages] = useState([]);
    let [productmainImg, setProductMainImages] = useState('');
    let [showImg, setshowImg] = useState('');
    let [color, setColor] = useState(false);
    let [size, setSize] = useState(false);

    let [choose_color, setchoose_color] = useState('');
    let [choose_size, setchoose_size] = useState('');
    const currency = useSelector(state => state.setting.currency);
    const basepath=useSelector((state) => state.setting.basepath);
    const navigate = useNavigate();

    const { t } = useTranslation()


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 10
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };

    const [qty, setQty] = useState(1);
   

    useEffect(() => {
        setColor(false)
        setSize(false)
        dispatch(getCurrencydata());
        axiosClient
            .get(`product/details?id=${props.id}`)
            .then(({ data }) => {
                data.color && setColor(data.color.split(","))
                if (data.size && data.size.length !== 0) {
                    setSize(data.size);
                }
                data.size && setchoose_size(data.size[0])
                setProductDetails(data);
                setProductMainImages(data.image_path);
                setshowImg(data.image_path)
                setProductImages(data.product_image);
                setProductDiscount(data.discount)
            });
    }, [url]);


    useEffect(() => {
        if (currency.length > 0 && mainCurrency) {
            for(let crc of currency) {
                if ( crc.currency_name == 'EURO' ) {
                    setUsdToEuroRate(parseFloat(crc.par_dollar_rate));
                } else if ( crc.currency_name == 'UAH' ) {
                    setUsdToUahRate(parseFloat(crc.par_dollar_rate));
                }
            }
        }
    }, [currency])
    
    useEffect(() => {
        if ( usdToEuroRate !== 1 && usdToUahRate !== 1 ) setEuroToUahRate(((100 / (usdToEuroRate * 100)) * usdToUahRate).toFixed(2));
    }, [usdToUahRate])
    
    const qtyChange = (typ2) => {
        if (typ2 === "plus") {
            setQty(pre => pre + 1)
        }
        if (typ2 === "neg") {
            setQty(pre => pre <= 1 ? 1 : pre - 1)
        }
    };
    let totalDiscount = 0;
    var discountPrc = 0;

    var discountPercentageCal = 0;
    if (offerId > 0) {
        if (productDetals.offer_type == 0) {
            totalDiscount = Math.round(productDetals.offer_amount);
            discountPrc = Math.round(totalDiscount);
        }
        if (productDetals.offer_type == 1) {
            let totaldis = (productDetals.current_sale_price * productDetals.offer_amount) / 100;
            totalDiscount = Math.round(totaldis);
            discountPrc = Math.round(productDetals.offer_amount);
        }
    }
    else {
        if (productDetals.discount > 0) {
            if (productDetals.discount_type == 0) {
                totalDiscount = Math.round(productDetals.discount);
                discountPrc = Math.round(totalDiscount);
            }
            if (productDetals.discount_type == 1) {
                let totaldis = (productDetals.current_sale_price * productDetals.discount) / 100;
                totalDiscount = Math.round(totaldis);
                discountPrc = Math.round(totalDiscount);
            }
        }
    }
    const addToCart = (item, type) => {
        if ( item.currency_type == 'USD' && mainCurrency == 'EURO' ) currencyRate = usdToEuroRate;
        if ( item.currency_type == 'USD' && mainCurrency == 'UAH' ) currencyRate = usdToUahRate;
        if ( item.currency_type == 'EURO' && mainCurrency == 'UAH' ) currencyRate = euroToUahRate;
        if ( item.currency_type == 'EURO' && mainCurrency == 'USD' ) currencyRate = (100 / (usdToEuroRate * 100)).toFixed(2);
        if ( item.currency_type == 'UAH' && mainCurrency == 'USD' ) currencyRate = (100 / (usdToUahRate * 100)).toFixed(2);
        if ( item.currency_type == 'UAH' && mainCurrency == 'EURO' ) currencyRate = (100 / (euroToUahRate * 100)).toFixed(2);
        if ( item.currency_type == mainCurrency ) currencyRate = 1;
        dispatch(
            cartActions.addItem({
                id: item.id,
                productName: item.name,
                initialPrice: item.current_sale_price - discountPrc,
                price: (currencyRate * (item.current_sale_price - discountPrc)).toFixed(2),
                imgUrl: item.image_path,
                offerId: offerId,
                currency: item.currency_type
            })
        );
        let data = { id: item.id, qty: qty };
        dispatch(cartActions.itemIncDic(data));

        type == 'buy' && navigate("/checkout");
        type != 'buy' && toast.success(`${t("product_added_successfully")}`);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [showImgURl, setshowImgURl] = useState();

    const showPrductDetails = (url) => {
        setshowImgURl(url);
        handleOpen();
    }

    return <section className="mb-4 pt-4">
        <Container className={'main-content'}>
            <div className="bg-white shadow-sm p-4">
                <Row>
                    <Col lg="5" md='12'>
                        <div className="mb-4 mb-lg-0 imgdiv" style={{cursor: 'pointer'}} onClick={() => showPrductDetails(showImg)}>
                            <img className="product__img" src={`${basepath}/${showImg}`} alt="" />
                        </div>

                        <div>
                            <Carousel
                                responsive={responsive}
                                autoPlay={false}
                                infinite={true}
                                transitionDuration={300}
                                autoPlaySpeed={3000}
                                renderDotsOutside={true}
                            >
                                <div className="product_item_imgdiv"><img onClick={() => setshowImg(productmainImg)} className="productitem" src={`${basepath}/${productmainImg}`} alt="" /></div>
                                {
                                    productImg.map((imgData) => {
                                        return <div className="product_item_imgdiv"><img onClick={() => setshowImg(imgData.image)} className="productitem" src={`${basepath}/${imgData.image
                                            }`} alt="" /></div>
                                    })
                                }

                            </Carousel>
                        </div>
                    </Col>
                    <Col lg="7" md="12">
                        <div className="product__details">
                            <h1 className="mb-2 fs-20 fw-600"> {productDetals.name} </h1>
                            <div className="product__rating d-flex align-items-center gap-5 mb-3">
                                <div>
                                    <span>
                                        <i className="ri-star-s-fill"></i>
                                    </span>
                                    <span>
                                        <i className="ri-star-s-fill"></i>
                                    </span>
                                    <span>
                                        <i className="ri-star-s-fill"></i>
                                    </span>
                                    <span>
                                        <i className="ri-star-s-fill"></i>
                                    </span>
                                    <span>
                                        <i className="ri-star-s-fill"></i>
                                    </span>
                                </div>
                                <p>
                                    (<span>4.5</span> {t("ratings")})
                                </p>
                            </div>
                            <div className="d-flex align-items-center gap-5">
                                <span className="product__price">
                                    {currency_symbol[productDetals.currency_type]} {productDetals.current_sale_price - productDiscount}
                                </span>
                                {productDiscount > 0 && <del>{currency_symbol[productDetals.currency_type]} {Math.round(productDetals.current_sale_price)}</del>}
                            </div>


                            {color && <div className="sizecolor_div"><div className="nametxt"><p>{t("color")}:</p> </div>
                                {
                                    color.map(colordata => <span className={`color_item ${colordata == choose_color && 'activecolorst'}`} onClick={() => setchoose_color(colordata)} style={{ 'background': colordata }} ></span>)
                                }
                            </div>}


                            <div className="sizecolor_div"><div className="nametxt"><p>{t("quantity")} :</p></div>


                                <div className="input__button__div">
                                    <AiFillMinusCircle className="itemBtn__st" onClick={() => qtyChange('neg')} />
                                    <div><input readOnly type="number" value={qty} /></div>
                                    <AiFillPlusCircle className="itemBtn__st" onClick={() => qtyChange('plus')} />
                                </div>
                            </div>

                            <div className="sizecolor_div"><div className="nametxt"><p>{t("total_price")} :  </p></div> <div><span className="payabletxt">{currency_symbol[productDetals.currency_type]} {(productDetals.current_sale_price - productDiscount) * qty}</span></div> </div>
                            {
                                size && <div className="sizecolor_div"><div className="nametxt"><p>{t("size")}:</p>
                                </div>
                                    <div className="sizediv">

                                        {size.map((sizedata) => <span className={`sizedata ${sizedata == choose_size && 'activecolorst'}`} onClick={() => setchoose_size(sizedata)}>{sizedata}</span>)}
                                    </div>
                                </div>

                            }
                            <div className="sizecolor_div">
                                <motion.button
                                    whiletap={{ scale: 1.2 }}
                                    className="buy__btn btninner"
                                    onClick={() => addToCart(productDetals, 'cartadd')}
                                >

                                    < AiOutlineShopping />
                                    {t("add_to_cart")}
                                </motion.button>

                                <motion.button
                                    whiletap={{ scale: 1.2 }}
                                    className="buy__btn buyNow btninner"
                                    onClick={() => addToCart(productDetals, 'buy')}
                                >
                                    <AiOutlineShoppingCart /> {t("buy_now")}
                                </motion.button>

                            </div>

                            {/* <div className="sizecolor_div"><div className="nametxt"><p>Share:</p> </div>
               <AiFillFacebook/> 
               <AiFillTwitterSquare/>
               <AiOutlineWhatsApp/>
               </`div> */}
                  `      </div>

                    </Col>
                </Row>
            </div>

        </Container>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className='imageDetail'>
                    <img className="product__img" style={{height: '100%', objectFit: 'contain', width: 'inherit'}} src={`${basepath}/${showImgURl}`} alt="" />
                </div>
            </Box>
        </Modal>
    </section>
}

export default ProductDetailsCart;
