import React, { useState } from "react";
import "../styles/cart.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Col, Container, Row } from "reactstrap";
import { motion } from "framer-motion";
import { cartActions } from "../redux/slices/cartSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProviders";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CONFIG } from "../config";

const Cart = (props) => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const { orderList } = useStateContext();
  const isLogin = useSelector((state) => state.user.isLogin)
  const navigate = useNavigate();
  const { t } = useTranslation()

  function checkout() {
    if (isLogin) {
      props.action(1)

    } else {
      toast.error('Login First')
      navigate("/login")
    }
  }

  return (
    <Helmet>
      {/* <CommonSection title="Shopping Cart" /> */}
      <Container>
        <Row className="mt-1 mb-2">
          <Col lg="12" md="12">
            {cartItems.length === 0 ? (
              <h2 className="fs-4 text-center"> {t("no_items")} </h2>
            ) : (
              <table className="table borderd checouttable">
                <thead>
                  <tr className="carthead__st">
                    <th>{t("image")}</th>
                    <th>{t("title")}</th>
                    <th>{t("price")}</th>
                    <th>{t("qty")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <Tr item={item} key={index} />
                  ))}
                </tbody>
              </table>
            )}

            <div>
              {
                cartItems.length > 0 ? <button className="buy__btn w-100" onClick={checkout}>
                  <Link to="/checkout"> {t("checkout")}</Link>
                </button> :
                  <Link to="/all/product" className="w-100"> <button className="buy__btn w-100" > {t("shop")}  </button> </Link>

              }


              {/* <button className="buy__btn w-100 mt-3">
                <Link to="/shop"> Continue Shopping </Link>
              </button> */}
            </div>
          </Col>
          {/* <Col lg="3" md="6">
            <div>
              <h6 className="d-flex align-items-center justify-content-between">
                Subtotal
                <span className="fs-4 fw-bold">${totalAmount} </span>
              </h6>
            </div>
            <p className="fs-6 mt-2">
              Taxex and shipping will calculate in checkout
            </p>
            <div>
              <button className="buy__btn w-100" onClick={checkout}>
                <Link to="/checkout"> Checkout </Link>
              </button>

              <button className="buy__btn w-100 mt-3">
                <Link to="/shop"> Continue Shopping </Link>
              </button>
            </div>
          </Col> */}
        </Row>
      </Container>
    </Helmet>
  );
};

const Tr = ({ item }) => {
  const currency_symbol = {
    'USD': '$',
    'EURO': '€',
    'UAH': '₴',
  };
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(item.quantity);

  const qtyChange = (qt, id, typ2) => {
    let qty = qt;
    if (qt <= 1 && typ2 === "neg") {
      if (window.confirm('Do you want to delete the product in the cart?') == true) deleteProduct();
      return false;
    }
    if (typ2 === "plus") {
      qty = qt + 1;
      setQuantity((prev) => prev + 1);
    }
    if (typ2 === "neg") {
      qty = qt - 1;
      setQuantity((prev) => prev - 1);
    }
    let data = { id: id, qty: qty };
    dispatch(cartActions.itemIncDic(data));
  };

  const deleteProduct = () => {
    dispatch(cartActions.deleteItem(item.id));
  };

  return (
    <tr>
      <td>
        <img src={`${CONFIG.backendURL}/${item.imgUrl}`} alt="" />
      </td>
      <td>{item.productName}</td>
      <td>{item.initialPrice} {currency_symbol[item.initialCurrency]}</td>
      <td className="qitemst">
        <div className="itemabtr">
          <button
            className="qt_btn bsewd"
            onClick={() => {
              qtyChange(item.quantity, item.id, "neg");
            }}
          >
            -
          </button>
          <div><input type="number" defaultValue={item.quantity} className="qtyinput" value={quantity} /></div>


          <button
            className="qt_btn  bsewd"
            onClick={() => {
              qtyChange(item.quantity, item.id, "plus");
            }}
          >
            +
          </button>

        </div>
      </td>
      <td>
        <motion.i
          whiletap={{ scale: 1.2 }}
          onClick={deleteProduct}
          className="ri-delete-bin-line"
        ></motion.i>
      </td>
    </tr>
  );
};

export default Cart;
