import React, { useRef, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Link } from "react-router-dom";
import {
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Button,
} from "reactstrap";

import { useEffect } from "react";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

const UserAddress = () => {
    const { t } = useTranslation();
    const [emailShippingRef, setemailShippingRef] = useState('');
    const [first_nameShippingRef, setfirst_nameShippingRef] = useState('');
    const [last_nameShippingRef, setlast_nameShippingRef] = useState('');
    const [addressShippingRef, setaddressShippingRef] = useState('');
    const [countryShippingRef, setcountryShippingRef] = useState('');
    const [cityShippingRef, setcityShippingRef] = useState('');

    const [divisionShippingRef, setDivisionShippingRef] = useState('');
    const [districtShippingRef, setDistrictShippingRef] = useState('');

    const [stateShippingRef, setstateShippingRef] = useState('');
    const [zipShippingRef, setzipShippingRef] = useState('');
    const [addressBillingRef, setaddressBillingRef] = useState('');
    const [countryBillingRef, setcountryBillingRef] = useState('');
    const [cityBillingRef, setcityBillingRef] = useState('');
    const [stateBillingRef, setstateBillingRef] = useState('');

    const [divisionBillingRef, setdivisionBillingRef] = useState('');
    const [districtBillingRef, setdistrictBillingRef] = useState('');

    const [zipBillingRef, setzipBillingRef] = useState('');
    const [shippingPhone, setshippingPhone] = useState('');

    const [billingPhone, setbillingPhone] = useState('');
    const [firstnameBillingRef, setfirstnameBillingRef] = useState('');
    const [lastnameBillingRef, setlastnameBillingRef] = useState('');
    const [emailBillingingRef, setemailBillingRef] = useState('');

    const [countryList, setCountryList] = useState([]);
    const [divisionList, setdivisionList] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [billingDivisionList, setBillingDivisionList] = useState([]);
    const [billingDistrictList, setBillingDistrictList] = useState([]);
    const [userAddressData, setUserAddressData] = useState([]);
    const [isSubmitError, setisSubmitError] = useState(false)

    const userinfo = useSelector((state) => state.user.userInfo);

    // Function to fetch country data from REST Countries API
    async function fetchCountryData(countryName) {
        const username = 'reon63'; // Replace with your GeoNames username
        try {
            const response = await fetch(`http://secure.geonames.org/countryInfoJSON?country=${countryName}&username=${username}`);
            const data = await response.json();
            return data.geonames[0]; // Assuming the first result is the correct country
        } catch (error) {
            console.error('Error fetching country data:', error);
            throw error;
        }
    }

    // Function to fetch divisions (states/provinces) from GeoNames API
    async function fetchChildren(code) {
        const username = 'reon63'; // Replace with your GeoNames username
        try {
            const response = await fetch(`https://secure.geonames.org/childrenJSON?geonameId=${code}&username=${username}`);
            const data = await response.json();
            return data.geonames; // Assuming the divisions are in the 'geonames' array
        } catch (error) {
            console.error('Error fetching divisions:', error);
            throw error;
        }
    }

    // Example usage
    async function getDivisions(countryName) {
        try {
            // Fetch country data
            const countryData = await fetchCountryData(countryName);
            // Extract country code
            const countryCode = countryData.geonameId;
            
            // Fetch divisions for the country
            const divisions = await fetchChildren(countryCode);

            if(divisions) {
                return divisions;
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    async function getDistricts(divisionCode) {
        try {
            // Fetch districts for the country
            const districts = await fetchChildren(divisionCode);

            if(districts) {
                return districts;
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    }

    useEffect(() => {

        try {
            axiosClient.get("country/list").then(({ data }) => {
                setCountryList(data)
            }).catch((err)=>{ console.error(err) });
    
            axiosClient.get("user/shipping/billing/address/get?userId=" + JSON.parse(userinfo).id).then(({ data }) => {
                if(data.data){
                setUserAddressData(data.data);
                setemailShippingRef(data.data.email);
                setfirst_nameShippingRef(data.data.first_name);
                setlast_nameShippingRef(data.data.last_name);
                setaddressShippingRef(data.data.shipping_address);
                setcountryShippingRef(data.data.shipping_country);
                setcityShippingRef(data.data.shipping_city);
                setstateShippingRef(data.data.shipping_state);
    
                setDivisionShippingRef(data.data.shipping_division);
                setDistrictShippingRef(data.data.shipping_district);
    
                setzipShippingRef(data.data.shipping_zip);
                setaddressBillingRef(data.data.billing_address);
                setcountryBillingRef(data.data.billing_country);
                setcityBillingRef(data.data.billing_city);
                setstateBillingRef(data.data.billing_state);
                setzipBillingRef(data.data.billing_zip);
                setshippingPhone(data.data.shipping_phone);
                setbillingPhone(data.data.billing_phone);
                setfirstnameBillingRef(data.data.billing_first_name);
                setlastnameBillingRef(data.data.billing_last_name);
                setemailBillingRef(data.data.billing_email);
    
                setdivisionBillingRef(data.data.billing_division);
                setdistrictBillingRef(data.data.billing_district);
    
    
                if(data.data.shipping_division){
                    getDivisionList(data.data.shipping_country, 'shipping')
                    getDistrictList(data.data.shipping_division, 'shipping')
                }
    
                if(data.data.billing_division){
                    getDivisionList(data.data.billing_country, 'billing')
                    getDistrictList(data.data.billing_division, 'billing')
                }
            }
    
            }).catch((err)=>{ console.error(err) });
        } catch(err) {
            console.error(err);
        }
         

    }, [])


    const getDivisionList = async (countryCode, state = '') => {
        const response = await getDivisions(countryCode);
        if (state == 'shipping') {
            setcountryShippingRef(countryCode);
            setdivisionList(response);
        } else {
            setcountryBillingRef(countryCode);
            setBillingDivisionList(response);
        }
    }

    const autofillBillingInfo = () => {
        setaddressBillingRef(addressShippingRef);
        setcountryBillingRef(countryShippingRef);
        setcityBillingRef(cityShippingRef);
        setstateBillingRef(stateShippingRef);
        setzipBillingRef(zipShippingRef);
        setbillingPhone(shippingPhone);
        setfirstnameBillingRef(first_nameShippingRef);
        setlastnameBillingRef(last_nameShippingRef);
        setemailBillingRef(emailShippingRef);

        setdivisionBillingRef(divisionShippingRef);
        setdistrictBillingRef(districtShippingRef);
    }

    let getDistrictList = async (divisionId, state = '') => {
        const response = await getDistricts(divisionId);
        if(state == 'shipping') {
            setDivisionShippingRef(divisionId)
            setdistrictList(response)
        } else {
            setdivisionBillingRef(divisionId);
            setBillingDistrictList(response);
        }
    }


    const saveAddress = (event) => {
        event.preventDefault();
        const shippingInfo = {
            shipping_email: emailShippingRef,
            shipping_first_name: first_nameShippingRef,
            shipping_last_name: last_nameShippingRef,
            shipping_address: addressShippingRef,
            shipping_country: countryShippingRef,
            shipping_city: cityShippingRef,
            shipping_state: stateShippingRef,
            shipping_zip: zipShippingRef,

            shipping_division: divisionShippingRef,
            shipping_district: districtShippingRef,
            billing_division: divisionBillingRef,
            billing_district: districtBillingRef,

            billing_address: addressBillingRef,
            billing_country: countryBillingRef,
            billing_city: cityBillingRef,
            billing_state: stateBillingRef,
            billing_zip: zipBillingRef,
            billing_first_name: firstnameBillingRef,
            billing_last_name: lastnameBillingRef,
            shipping_phone: shippingPhone,
            billing_phone: billingPhone,
            billing_email: emailBillingingRef
      
        };
        
        if(!first_nameShippingRef 
            || !last_nameShippingRef 
            || !addressShippingRef 
            || !countryShippingRef 
            || !zipShippingRef 
            || !emailShippingRef 
            || !shippingPhone
        ){
            setisSubmitError(true)
            return
        }

        axiosClient
            .post('user/shipping/billing/address', shippingInfo)
            .then(({ data }) => {
                if (data.status == 200) {
                    toast.success(data.msg)
                }
            });
    }

    return (
        <Helmet title="Login">
            <section>
                <Container className={'main-content'}>
                    <form onSubmit={saveAddress}>
                        <Row className="dashboardCard">
                            <Col lg="12" className="addressPd"  >
                                <strong className="text-center">{t("shipping_address")}</strong>
                                <hr />
                                <div>
                                    <Form>
                                        <Row>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="firstname"
                                                    >
                                                        {t("first_name")}
                                                    </Label>
                                                    <Input
                                                        id="firstName"
                                                        className={`form-control ${isSubmitError && !first_nameShippingRef ? 'border border-danger' : ''}`}
                                                        defaultValue={first_nameShippingRef}
                                                        onInput={(data) => setfirst_nameShippingRef(data.target.value)}
                                                        name="first_name"
                                                        placeholder={t("first_name")}
                                                        type="email"
                                                        required
                                                    />
                                                    {isSubmitError && !first_nameShippingRef && (
                                                        <p className="text-danger">This field is required.</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="lastname"

                                                    >
                                                        {t("last_name")}
                                                    </Label>
                                                    <Input
                                                        id="lastname"
                                                        className={`form-control ${isSubmitError && !last_nameShippingRef ? 'border border-danger' : ''}`}
                                                        defaultValue={last_nameShippingRef}
                                                        onInput={(data) => { setlast_nameShippingRef(data.target.value) }}
                                                        name="last_name"
                                                        placeholder={t("last_name")}
                                                        type="text"
                                                        required
                                                    />
                                                    {isSubmitError && !last_nameShippingRef && (
                                                        <p className="text-danger">This field is required.</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>



                                        <Row>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="email"

                                                    >
                                                        {t("email")}
                                                    </Label>
                                                    <Input
                                                        id="email"
                                                        className={`form-control ${isSubmitError && !emailShippingRef ? 'border border-danger' : ''}`}
                                                        defaultValue={emailShippingRef}
                                                        onInput={(data) => { setemailShippingRef(data.target.value) }}
                                                        name="email"
                                                        placeholder={t("email")}
                                                        type="email"
                                                        required
                                                    />
                                                    {isSubmitError && !emailShippingRef && (
                                                        <p className="text-danger">This field is required.</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="phone"

                                                    >
                                                        {t("phone")}
                                                    </Label>
                                                    <Input
                                                        id="phone"
                                                        className={`form-control ${isSubmitError && !shippingPhone ? 'border border-danger' : ''}`}
                                                        defaultValue={shippingPhone}
                                                        onInput={(data) => { setshippingPhone(data.target.value) }}
                                                        name="last_name"
                                                        placeholder={t("phone")}
                                                        type="text"
                                                        required
                                                    />
                                                    {isSubmitError && !shippingPhone && (
                                                        <p className="text-danger">This field is required.</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <Label
                                                htmlFor="shippingaddress"

                                            >
                                                {t("address")}
                                            </Label>
                                            <Input
                                                id="shippingaddress"
                                                name="address"
                                                className={`form-control ${isSubmitError && !addressShippingRef ? 'border border-danger' : ''}`}
                                                defaultValue={addressShippingRef}
                                                onInput={(data) => { setaddressShippingRef(data.target.value) }}

                                                placeholder={t("address")}
                                                type="email"
                                                required
                                            />
                                            {isSubmitError && !addressShippingRef && (
                                                <p className="text-danger">This field is required.</p>
                                            )}
                                        </FormGroup>


                                        <Row>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="shippingcountry"

                                                    >
                                                        {t("country")}
                                                    </Label>

                                                    <Input 
                                                        type="select" 
                                                        name="select" 
                                                        className={`form-control ${isSubmitError && !countryShippingRef ? 'border border-danger' : ''}`}
                                                        id="shippingcountry" 
                                                        defaultValue={countryShippingRef} 
                                                        onInput={(data) => { getDivisionList(data.target.value, 'shipping') }} 
                                                        placeholder={t("country")} 
                                                        required>
                                                        <option value="">{t("select_country")}</option>
                                                        {   countryList &&
                                                            Object.keys(countryList).map((data, index) => {
                                                                return <option key={data} value={data} selected = {data == countryShippingRef ? true : false}>{countryList[data].name}</option>
                                                            })
                                                        }

                                                    </Input>
                                                    {isSubmitError && !countryShippingRef && (
                                                        <p className="text-danger">This field is required.</p>
                                                    )}

                                                </FormGroup>
                                            </Col>

                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="shippingdivision"
                                                    >
                                                        {t("division")}
                                                    </Label>

                                                    <Input 
                                                        type="select" 
                                                        name="division" 
                                                        id="shippingdivision" 
                                                        defaultValue={divisionShippingRef} 
                                                        onInput={(data) => { getDistrictList(data.target.value, 'shipping') }} 
                                                        placeholder={t("division")} >
                                                        <option value="">{t("select_division")}</option>
                                                        {   divisionList &&
                                                            divisionList.map((data, index) => {
                                                                return <option key={data.geonameId} value={data.geonameId} selected = {data.geonameId == divisionShippingRef ? true : false}>{data.name}</option>
                                                            })
                                                        }

                                                    </Input>

                                                </FormGroup>
                                            </Col>

                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="shippingdistrict"

                                                    >
                                                        {t("district")}
                                                    </Label>


                                                    <Input type="select" name="district" id="shippingdistrict" defaultValue={districtShippingRef} onInput={(data) => { setDistrictShippingRef(data.target.value) }} placeholder={t("district")}>
                                                        <option value="">{t("select_district")}</option>
                                                        {   districtList && 
                                                            districtList.map((data, index) => {
                                                                return <option key={data.geonameId} value={data.geonameId} selected = {data.geonameId == districtShippingRef ? true : false}>{data.name}</option>
                                                            })
                                                        }

                                                    </Input>

                                                </FormGroup>
                                            </Col>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="postalcode"

                                                    >
                                                        {t("postal_code")}
                                                    </Label>
                                                    <Input
                                                        id="postalcode"
                                                        name="zip"
                                                        className={`form-control ${isSubmitError && !zipShippingRef ? 'border border-danger' : ''}`}
                                                        defaultValue={zipShippingRef}
                                                        onInput={(data) => { setzipShippingRef(data.target.value) }}
                                                        placeholder={t("postal_code")}
                                                        type="text"
                                                        required
                                                    />
                                                    {isSubmitError && !zipShippingRef && (
                                                        <p className="text-danger">This field is required.</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            {/* <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="shippingcity"
                                                        
                                                    >
                                                        City
                                                    </Label>
                                                    <Input
                                                        id="exampleEmail"
                                                        value={cityShippingRef}
                                                        name="city"
                                                        onInput={(data) => {setcityShippingRef(data.target.value) }} 
                                                        placeholder="City"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                            {/* <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="shippingstate"
                                                        
                                                    >
                                                        State
                                                    </Label>
                                                    <Input
                                                        id="exampleEmail"
                                                        value={stateShippingRef}
                                                        onInput={(data) => {setstateShippingRef(data.target.value) }} 
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col> */}

                                        </Row>
                                    </Form>

                                </div>

                            </Col>
                            <div className="d-flex justify-content-end align-items-center" style={{'padding': '0px 18px'}}>
                                <Button className="btn btn-success" onClick={autofillBillingInfo}>{ t('autofill') }</Button>
                            </div>
                            <Col lg="12" className="addressPd"  >
                                <strong className="text-center">{t("billing_address")}</strong>
                                <hr />
                                <div>
                                    <Form>
                                        <Row>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="firstName"
                                                    >
                                                        {t("first_name")}
                                                    </Label>
                                                    <Input
                                                        id="firstName"
                                                        defaultValue={firstnameBillingRef}
                                                        onInput={(data) => { setfirstnameBillingRef(data.target.value) }}
                                                        name="first_name"
                                                        placeholder={t("first_name")}
                                                        type="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="lastName"

                                                    >
                                                        {t("last_name")}
                                                    </Label>
                                                    <Input
                                                        id="lastName"
                                                        defaultValue={lastnameBillingRef}
                                                        onInput={(data) => { setlastnameBillingRef(data.target.value) }}
                                                        name="last_name"
                                                        placeholder={t("last_name")}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="Email"

                                                    >
                                                        {t("email")}
                                                    </Label>
                                                    <Input
                                                        id="Email"
                                                        defaultValue={emailBillingingRef}
                                                        onInput={(data) => { setemailBillingRef(data.target.value) }}
                                                        name="billing_email"
                                                        placeholder={t("email")}
                                                        type="email"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="Phone"

                                                    >

                                                        {t("phone")}
                                                    </Label>
                                                    <Input
                                                        id="Phone"
                                                        defaultValue={billingPhone}
                                                        onInput={(data) => { setbillingPhone(data.target.value) }}
                                                        name="Phone"
                                                        placeholder={t("phone")}
                                                        type="string"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label
                                                htmlFor="billingaddress"

                                            >
                                                {t("address")}
                                            </Label>
                                            <Input
                                                defaultValue={addressBillingRef}
                                                onInput={(data) => { setaddressBillingRef(data.target.value) }}
                                                id="billingaddress"
                                                name="Address"
                                                placeholder={t("address")}
                                                type="text"
                                            />
                                        </FormGroup>

                                        <Row>
                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="billingCountry"
                                                    >
                                                        {t("country")}
                                                    </Label>
                                                    <Input type="select" name="select" id="billingCountry" defaultValue={countryBillingRef} onInput={(data) => { getDivisionList(data.target.value, 'billing') }} placeholder={t("country")}>
                                                        <option value="">{t("select_country")}</option>
                                                        {   countryList &&
                                                            Object.keys(countryList).map((data, index) => {
                                                                return <option key={data} value = {data} selected = {data == countryBillingRef ? true : false}>{countryList[data].name}</option>
                                                            })
                                                        }
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="billingdivision"

                                                    >
                                                        {t("division")}
                                                    </Label>

                                                    <Input type="select" name="division" id="billingdivision" defaultValue={divisionBillingRef} onInput={(data) => { getDistrictList(data.target.value, 'billing') }} placeholder={t("division")}>
                                                        <option value="">{t("select_division")}</option>
                                                        {   billingDivisionList &&
                                                            billingDivisionList.map((data, index) => {
                                                                return <option key={data.geonameId} value={data.geonameId} selected = {data.geonameId == divisionBillingRef ? true : false}>{data.name}</option>
                                                            })
                                                        }

                                                    </Input>

                                                </FormGroup>
                                            </Col>

                                            <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="billingdistrict" >
                                                        {t("district")}
                                                    </Label>


                                                    <Input type="select" name="district" id="billingdistrict" defaultValue={districtBillingRef} onInput={(data) => { setdistrictBillingRef(data.target.value) }} placeholder={t("district")}>
                                                        <option value="">{t("select_district")}</option>
                                                        {   billingDistrictList &&
                                                            billingDistrictList.map((data, index) => {
                                                                return <option key={data.geonameId} value={data.geonameId} selected = {data.geonameId == districtBillingRef ? true : false} >{data.name}</option>
                                                            })
                                                        }

                                                    </Input>

                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="billingpostal"
                                                    >
                                                        {t("postal_code")}
                                                    </Label>
                                                    <Input
                                                        defaultValue={zipBillingRef} onInput={(data) => { setzipBillingRef(data.target.value) }}
                                                        id="billingpostal"
                                                        name="zip"
                                                        placeholder={t("postal_code")}
                                                        innerRef={zipBillingRef}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>



                                            {/* <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="billingcity"
                                                        
                                                    >
                                                        City
                                                    </Label>
                                                    <Input

                                                        id="exampleEmail"
                                                        name="city"
                                                        placeholder="City"
                                                        
                                                        value={cityBillingRef} onInput={(data) => {setcityBillingRef(data.target.value) }} 
                                                        
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col> */}
                                        </Row>
                                        <Row>
                                            {/* <Col lg="6" >
                                                <FormGroup>
                                                    <Label
                                                        htmlFor="billingstate"
                                                        
                                                    >
                                                        State
                                                    </Label>
                                                    <Input
                                                    setstateBillingRef
                                                    setzipBillingRef
                                                       value={stateBillingRef} onInput={(data) => {setstateBillingRef(data.target.value) }} 
                                                        
                                                        id="exampleEmail"
                                                        name="state"
                                                        placeholder="State"
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col> */}

                                        </Row>

                                    </Form>
                                </div>
                                <div className="d-flex float-right">
                                    <Button style={{ width: "100%" }} type="submit" > {t("save")} </Button>
                                </div>

                            </Col>
                        </Row>
                    </form>
                </Container>

            </section>
        </Helmet>
    );
};

export default UserAddress;
