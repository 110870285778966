import React,{useState} from 'react';
import Helmet from '../components/Helmet/Helmet';
import { Link } from 'react-router-dom';
import { useHistory,useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import '../styles/authentications.css';                       
import axiosClient from '../axios-client';
import { useSelector, useDispatch } from "react-redux";
import { userAction } from '../redux/slices/userSlice';
import { toast } from "react-toastify";
import { getWishcount } from '../redux/slices/settingSlice';
import { useTranslation } from 'react-i18next';


const Login = () => {
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const islogin = useSelector((state) => state.user.islogin);
  const dispatch = useDispatch();
  const [email,setEmail] = useState();
  const [password,setPassword] = useState();
  const [loginError,setloginError] = useState(false);
  const navigate=useNavigate();
  const { t } = useTranslation()
  const handleLoginSubmit = e => {   
    e.preventDefault();
  }

  const submitForm = () => {
    const info={
      email:email,
      password:password,
    }
    let infodata = {}
    axiosClient.post("login",info).then(({data})=>{
  
     if(data.status==422){
      setloginError(true)
      toast.error(`${t(data.msg)}`,{theme: "colored"});
     }
     if(data.status==200){
      infodata={
        token:data.token,
        userInfo:data.user,
      }
      dispatch(userAction.setLoginInfo(infodata));
      dispatch(getWishcount());
      toast.success(`${t("successfully_login")}`)

      navigate('/home')
     }
    })
  }

  return (
    <Helmet title = "Login">
        <section>
            <Container>
              <Row>
                <Col lg='12' className='login-feture py-5'>
                    <h3 className='fw-bold fs-4 mb-4 text-center' style={{textTransform: "capitalize"}}> {t("signin")} </h3>
                    <div>
                      
                      <Form className = 'auth__form' onSubmit={handleLoginSubmit}>

                          <FormGroup className='form__group'>
                            <Label> {t("email_address")}* </Label>
                            <Input className='form-control'
                                   required 
                                   type= "email" 
                                   id='exampleEmail'name='email' 
                                   placeholder={`${t("enter_your_email")}`}
                                   defaultValue = {email}
                                   autoComplete='false'
                                   onChange={e=> setEmail(e.target.value)} />
                          </FormGroup>

                          <FormGroup className='form__group'>
                            <Label style={{textTransform: "capitalize"}}> {t("password")} * </Label>
                            <Input type="password" name='name'
                                   id='examplepass'
                                   required
                                   placeholder={`${t("enter_your_password")}`}
                                   defaultValue = {password}
                                   autoComplete='false'
                                   onChange={e=> setPassword(e.target.value)} />
                          </FormGroup>
                          {
                            loginError&& <div><h5 className="loinerrtxt"> {t("incorrect_email_password")} </h5> <br/></div> 
                          }
                          

                          <FormGroup className='form__group'>
                              <button onClick={submitForm} className="auth__btn" style={{textTransform: "capitalize"}}> {t("submit")} </button>
                          </FormGroup>
                          <p className='account_state'> 
                          {t("don't_have_an_accout")} ? {""}  
                            <Link className= 'text-black fw-bold' to="/Signup" style={{textTransform: "capitalize"}}>  {t("create_an_account")} </Link>
                          </p>
                      </Form>

                  </div>
                </Col>
              </Row>
            </Container>
      </section>
    </Helmet>
  )
}

export default Login;