import React, { useEffect, useState } from 'react'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import { Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import "../styles/contact.css"
import { toast } from "react-toastify";
import { FaPhone } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import axiosClient from "../axios-client";


const ContactUs = () => {
    const { aboutus } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
    })

    const [name, setname] = useState('');
    const [email, setemail] = useState('');
    const [text, setptext] = useState('');
    const { t } = useTranslation()
    const submitdata = () => {
        const data = {
            name: name,
            email: email,
            text: text
        }
        axiosClient
            .post('user/send/email', data)
            .then(({ data }) => {
                if (data.status == 200) {
                    toast.success(data.msg)
                }
            });
        setname('');
        setemail('');
        setptext('');
    }


    return (
        <Helmet title='Contact Us'>
            <CommonSection title={t("contact_us")} />
            {/* <Container>
                <div className="row mt-5 mb-5 maindiv">
                    <div className="col-sm-6 locationBackground">
                        <div className='addressinfo'>
                            <div className='infoflex'>
                                <div className='titlewidth'> <span className='icondata'><AiOutlinePhone /></span> <span className='title_tx'>Phone</span> </div>
                                <p className='titleinfowidth'>0179297777</p>
                            </div>

                            <div className='infoflex'>
                                <div className='titlewidth'> <span className='icondata'><AiOutlineMail /></span> <span className='title_tx'>Email</span></div>
                                <p className='titleinfowidth'>dfh@gmail.com</p>
                            </div>
                            <div className='infoflex'>
                                <div className='titlewidth'> <span className='icondata'><AiOutlineEnvironment /></span> <span className='title_tx'>Location</span> </div>
                                <p className='titleinfowidth'>medium-emphasis buttons. They contain actions that are important but aren't the primary</p>
                            </div>


                        </div>
                    </div>
                    <div className="col-sm-6 rightbackground">
                        <div className='coutactinputdiv'>
                            <FormGroup>
                                <Input className='inputtxtsiz' value={name} onChange={ev => setname(ev.target.value)} type="text" name="email" id="exampleEmail" placeholder="Name" required />
                            </FormGroup>
                            <FormGroup>
                                <Input className='inputtxtsiz' value={email} onChange={ev => setemail(ev.target.value)} type="email" name="email" id="exampleEmail" placeholder="Email" />
                            </FormGroup>
                            <FormGroup>
                                <textarea className='form-control inputtxtsiz' value={text} onChange={ev => setptext(ev.target.value)} rows={5} col={20}> </textarea>
                            </FormGroup>


                            <Button type='submit' onClick={submitdata} variant="contained" className='sendbtn'  >
                                Send
                            </Button>

                        </div>



                    </div>
                </div>


            </Container> */}
            <div className="container px-4 py-5 main-content custom-height">
                <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5">
                    <div className="col-md-12 mx-auto col-lg-6 m-0">
                        <div className="row row-cols-1 row-cols-sm-2 g-4">
                        
                        <div className="col d-flex align-items-start">
                            <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3 mt-1">
                                <svg className="bi" width="1em" height="1em"> <FaPhone fontSize="16px" /></svg>
                            </div>
                            <div>
                            <h4 className="fw-semibold mb-0 text-body-emphasis"> {t("phone")}: </h4>
                            <a className="text-body-secondary contact_info" href='tel:09561 / 23 96 873'> 09561 / 23 96 8736 </a>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start">
                            <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                <svg className="bi" width="1em" height="1em"> <MdEmail/></svg>
                            </div>
                            <div>
                            <h4 className="fw-semibold mb-0 text-body-emphasis">{t("email")}: </h4>
                            <a className="text-body-secondary email_linksa contact_info" style={{textTransform: 'lowercase'}} href='mailto:buchhaltung@biomaschine.de' >info@biomaschine.de</a>
                            </div>
                        </div>

                        <div className="col d-flex align-items-start">
                            <div className="icon-square text-body-emphasis d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                <svg className="bi" width="1em" height="1em"> <MdLocationPin/></svg>
                            </div>
                            <div>
                            <h4 className="fw-semibold mb-0 text-body-emphasis"> {t("location")}:</h4>
                            <p className="text-body-secondary contact_info"><b>Biomaschine.de inh.</b>,<br/>
                                Steinweg 5 DE-96271</p>
                            </div>
                        </div>

                        {/* <div className="col d-flex align-items-start">
                            <div className="icon-square text-body-emphasis bg-body-secondary d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                                <svg className="bi" width="1em" height="1em"> <BsWhatsapp/></svg>
                            </div>
                            <div>
                            <h4 className="fw-semibold mb-0 text-body-emphasis">{t("whatsapp")} </h4>
                            <a className="text-body-secondary contact_info"> +880 1329-657096 </a>
                            </div>
                        </div> */}
                    </div>
                    </div>
                    <div className="col-md-12 mx-auto col-lg-6">
                            <form className="p-3 p-md-5 border rounded-0 bg-body-tertiary" onSubmit={e => e.preventDefault()}>
                                <div className="form-floating mb-3">
                                    <Input className='inputtxtsiz' defaultValue={name} onChange={ev => setname(ev.target.value)} type="text" name="email" id="exampleEmail" placeholder={t("name")} required />
                                    <label htmlFor="floatingInput">{t("name")}</label>
                                </div>
                                <div className="form-floating mb-3">
                                    <Input className='inputtxtsiz' defaultValue={email} onChange={ev => setemail(ev.target.value)} type="email" name="email" id="exampleEmail" placeholder={t("email")} />
                                    <label htmlFor="floatingInput">{t("email")} </label>
                                </div>
                                <div className="form-floating mb-3">
                                    <textarea className='form-control inputtxtsiz textarea-rows' value={text} rows="20" onChange={ev => setptext(ev.target.value)}> </textarea>
                                    <label htmlFor="floatingPassword"> {t("input_your_message")} </label>
                                </div>
                                <button onClick={submitdata} className="w-100 btn btn-lg btn-primary" type="submit"> {t("send")} </button>
                            </form>
                    </div>                    
                </div>
            </div>
        </Helmet >
    )
};

export default ContactUs;