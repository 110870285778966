import React, { Suspense } from "react";
import "./App.css";
import "./i18n";
import Layout from "./components/Layout/Layout";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  let queryclient = new QueryClient();
  return (
    <>
      <Suspense fallback="loading">
        <QueryClientProvider client={queryclient}>
          <Layout />
        </QueryClientProvider>
      </Suspense>
    </>
  );
}

export default App;
