import React, { useEffect, useState } from 'react'
import Helmet from '../Helmet/Helmet';
import CommonSection from '../UI/CommonSection';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axiosClient from '../../axios-client';
import { useStateContext } from "../../contexts/ContextProviders";
import { Link } from 'react-router-dom';

const AllCategoris = () => {
  const { lang } = useStateContext();
  const basepath = useSelector((state) => state.setting.basepath);
  const { t } = useTranslation();
  const [categoryList, setCategoryList] = useState();
  useEffect(() => {
    axiosClient
      .get(`all/category/subcategory?lang=${lang}`)
      .then(({ data }) => {
        setCategoryList(data);
      }).catch((error) => { console.error(error); });
  }, [])

  return (
    <Helmet title={t("all") + " " + t("categories")}>
    <CommonSection title={t("all") + " " + t("categories")}/>
      <div className='container main-content'>
          <div className='row mt-3'>
              <div className="col-md-12">
                  <div className="card text-capitalize">
                      <h2> {t("product")} {t("categories")} </h2>
                  </div>
              </div>
          </div>
          <div className='row custom-height'>
            {
              categoryList ? categoryList.map((item, index) => {
                return (
                  <div className="col-md-12" key={item.id}>
                      <div className="card">
                        <Link to={`/products/list/${item.id}/0/0/0/0`}>
                            <div className='card-header text-black' style={{fontSize: '30px'}}> 
                              <img src={`${basepath}/${item.image}`} alt='Category Image' style={{width: '100px', marginRight: '35px'}} />
                              {item.name}
                            </div>
                        </Link>
                        <div className='row mt-2'>
                          {
                            item.subcategory && item.subcategory.map((subitem, subindex) => {
                              return (
                                <Link to={`/products/list/${subitem.category_id}/${subitem.id}/0/0/0`}>
                                  <div className="col-md-12" key={subitem.id}>
                                    <div className='card-body p-2 shadow-sm mb-2 bg-white rounded'> 
                                      <div className='card-title mb-0 text-black'>
                                          {subitem.name}
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              )
                            })
                          }
                        </div>
                      </div>
                  </div>
                )
              }) : (
                <p className='p-4 display-4'>
                  There are no any category.
                </p>
              )
            }
          </div>
      </div>
    </Helmet>
  )
}

export default AllCategoris;